import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Box, Button as MUButton, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ROUTES from 'constants/routes';
import colors from 'constants/colors';
import links from 'constants/externalLinks';
import Images from 'assets/img';

const useStyles = makeStyles(
  (theme) => ({
    endIcon: {
      transform: 'rotate(180deg)',
    },
    button: {
      textTransform: 'capitalize',
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '18px',
      border: 'none',
      color: colors.darkBlue,
      '&:hover': {
        border: 'none',
      }
    },
    footer: {
    },
    linkedin: {
      marginRight: theme.spacing(5),
    },
    linkedinIcon: {
      '& path': {
        fill: colors.darkBlue,
      },
    },
  }),
  {
    name: 'ContactUsFooter',
  },
);

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  
  const handleLinkedInClick = useCallback((e) => {
    e.preventDefault();
    window.open(links.LINKED_IN_LINK, "_blank");
  }, []);

  return (
    <Box className={classes.footer} ml={-1} mt={3} mb={1.5}>
      <MUButton
        variant="outlined"
        color="secondary"
        onClick={handleLinkedInClick}
        className={clsx(classes.button, classes.linkedin)}
        startIcon={<Images.LinkedIn className={classes.linkedinIcon} />}
      >
        Check out our Linkedin
      </MUButton>
      <MUButton
        variant="outlined"
        color="secondary"
        onClick={() => history.push(ROUTES.PRIVACY)}
        className={classes.button}
        classes={{
          endIcon: classes.endIcon
        }}
        endIcon={<Images.LeftArrow />}
      >
        Privacy Policy
      </MUButton>
    </Box>
  );
};

export default Footer;
