const colors: { [key: string]: string } = {
  accent: '#1863F4',
  darkBlue: '#1A194F',
  white: '#FFFFFF',
  textDefault: '#333333',
  greyText: '#888888',
  textWhite: '#FFFFFF',
  textBeige: '#EFEEE7',
  lightGrey: '#EAEAEA',
  darkGrey: '#999999',
  backgroundDark: '#333333',
  backgroundWhite: '#FFFFFF',
  backgroundGrey: '#f8f8f8',
  inputGrey: '#888888',
};

export default colors;
