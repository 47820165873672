import axios, { Method } from 'axios';
import toastr from 'toastr';

type BaseRequestProps = {
  method: Method;
  url: any;
  params?: any;
  data?: any;
  headers?: any;
  api?: boolean;
};

const baseapiService = ({
  method,
  url,
  params,
  data = {},
  headers: customHeaders,
  api,
}: BaseRequestProps): Promise<any> => {
  let headers = {};
  if (localStorage.getItem('token')) {
    headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return axios({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}${api ? '/api' : ''}`,
    method,
    url,
    params,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...customHeaders
    }
  }).then(response => response.data)
  .catch(({ response }: any) => {
    toastr.error(response ? response.data?.message : '');
    Promise.reject();
  });
} 

export default baseapiService;
