import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      fontFamily: 'Silka',
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: '43px',
      letterSpacing: '-0.02em',
      color: theme.palette.common.white,
    },
    description: {
      fontFamily: 'Silka',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '-0.02em',
      color: theme.palette.common.white,
      whiteSpace: 'break-spaces',
      marginTop: theme.spacing(1)
    }
  }),
  {
    name: 'StoryText',
  },
);

interface StoryTextProps {
  title: string;
  description: string;
}

const StoryText = ({ title, description }: StoryTextProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </>
  );
}

export default StoryText;
