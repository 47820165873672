import React, { useCallback, useState } from 'react';
import {
  Modal,
  Typography,
  Button as MainButton,
  Box
} from '@material-ui/core';
import {
  USER_TRANSACRION_LIST
} from 'constants/routes';
import { 
  Alert
} from '@material-ui/lab';
import { Theme, makeStyles } from '@material-ui/core/styles';
import {
  Formik, Form, Field
} from 'formik';
import * as yup from 'yup';
import InputText from 'components/general/InputText/InputText';
import auth from 'services/api/AuthApi';
import { setIsLogged, useAppSettingsState } from 'context/appSettings';
import Images from 'assets/img';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup.object().shape({
  password: yup.string().label('Password').min(3).required().trim(),
  email: yup.string().label('Email').matches(emailRegExp, 'Email is not valid').required().trim(),
});

const useStyles = makeStyles<Theme>(
  (theme: Theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      outline: 'none',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      textDecoration: 'none',
      padding: theme.spacing(2, 4, 3),
      borderRadius: 8,
      maxWidth: 400,
      width: '100%',
      minHeight: 300,
    },
    form: {
      minHeight: 225,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    input: {
      marginTop: theme.spacing(2)
    },
    forgotPassword: {
      color: '#0645AD',
      cursor: 'pointer',
    },
    backButton: {
      border: '1px solid white',
    }
  }),
  { name: 'LoginModal' },
);

interface LoginModalProps {
  open: boolean;
  handleLoginClose: () => void;
}

const FORGOT_PASSWORD = 'forgot_password';
const LOGIN = 'login';

function LoginModal({ open, handleLoginClose }: LoginModalProps) {
  const classes = useStyles();
  const history = useHistory();
  const appContext = useAppSettingsState();

  const [view, setView] = useState(LOGIN)
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleForgotPasswordClick = useCallback(() => {
    setView(FORGOT_PASSWORD);
  }, []);

  const handleBackClick = useCallback(() => {
    setView(LOGIN);
  }, []);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleLoginClose}
      closeAfterTransition
    >
      <div className={classes.paper}>
        <Typography variant="h5">{view === LOGIN ? 'Login' : 'Restore Password'} Form</Typography>
        <Formik
          initialValues={{ 
            email: '',
            password: '',
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            auth.login({
              email: values.email,
              password: values.password
            }).then((data) => {
              if(data) {
                localStorage.setItem('token', data.token);
                appContext.appContextDispatch(setIsLogged(true));
                handleLoginClose();
                history.push(USER_TRANSACRION_LIST);
              }
            }).catch((error) => setErrorMessage(error.message || 'Some error with request'));
          }}
        >
          {() => (
            <Form className={classes.form}>
              {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <Field
                className={classes.input}
                component={InputText}
                name="email"
                label="Your email"
              />
              {view === LOGIN && (
                <Field
                  className={classes.input}
                  component={InputText}
                  type="password"
                  name="password"
                  label="Your password"
                />
              )}
              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                {view === LOGIN ? (
                  <Typography
                    className={classes.forgotPassword}
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot password?
                  </Typography>
                ) : (
                  <MainButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleBackClick}
                    className={classes.backButton}
                    startIcon={<Images.LeftArrow />}
                  >
                    Back
                  </MainButton>
                )}
                <Button
                  type="submit"
                  buttonType="blueBtn"
                  className={classes.button}
                >
                  {view === LOGIN ? 'Login' : 'Restore'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default LoginModal;
