import React, { createContext, useContext } from 'react';
import { ContextStateAction } from './Reducer/Actions';
import contextReducer, { Store, FieldType } from './Reducer';

export type StoreContextType = {
  store: Store;
  storeDispatch: (store: ContextStateAction) => void;
}

const initialStore: Store = {
  inputs: {
    [FieldType.Name]: '',
    [FieldType.Company]: '',
    [FieldType.Email]: '',
    [FieldType.Phone]: '',
    [FieldType.Topic]: '',
    [FieldType.Message]: '',
  },
  fieldName: null,
};

export const Context = createContext<StoreContextType>({
  store: initialStore,
  storeDispatch: (action: ContextStateAction) => {
    throw new Error('Using Context without the provider');
  },
});

export const ContextProvider: React.FC = ({ children }) => {
  const [store, storeDispatch] = React.useReducer(contextReducer, initialStore);

  return <Context.Provider value={{ store, storeDispatch }}>{children}</Context.Provider>;
};

export const useStore = () => useContext(Context);