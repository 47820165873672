import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import { getFieldErrorMeta } from 'hooks/getFieldErrorMeta';

type InputProps = TextFieldProps & {
  name: string;
  keyPress: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
type InputTextProps = InputProps & FieldProps;

const InputText = (props: InputTextProps) => {
  const {
    field,
    form,
    helperText,
    fullWidth = true,
    color = 'primary',
    type = 'text',
    variant = 'outlined',
    label,
    className,
    keyPress,
  } = props;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, e.target.value);
    form.setFieldTouched(field.name, true)
  }, [field.name]);

  const { showError, fieldError } = getFieldErrorMeta(form, field);

  const handleKeyDown = useCallback((e) => {
    if(keyPress && !showError) {
      form.setFieldTouched(field.name, false);
      keyPress(e);
    }
  }, [showError]);

  return (
    <TextField
      className={className}
      label={label}
      variant={variant}
      helperText={showError ? fieldError : helperText}
      fullWidth={fullWidth}
      color={color}
      multiline={type === 'textarea'}
      rows={10}
      type={type}
      error={showError}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

export default InputText;
