import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-simply-carousel';
import Images from 'assets/img';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: '#1A194F',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '38px',
        letterSpacing: '-0.02em',
      }
    },
    block: {
      position: 'relative',
      width: '100%',
      marginBottom: 56,
    },

    actionBtn: {
      display: 'none',
      width: 48,
      height: 48,
      minWidth: 48,
      alignSelf: 'center',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      opacity: 0.65,
      '&:hover': {
        opacity: 1,
      }
    },
    commentCard: {
      filter: 'drop-shadow(0px 0px 180px rgba(0, 0, 0, 0.08))',
      width: 1178,
      background: '#FFFFFF',
      borderRadius: 5,
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        width: 978,
      },
      [theme.breakpoints.down('md')]: {
        width: 778,
      },
      [theme.breakpoints.down('sm')]: {
        width: 378,
      },
      [theme.breakpoints.down('xs')]: {
        width: 278,
      },

      '& .comment': {
        marginTop: 24,
        fontWeight: 300,
        fontSize: 18,
        lineHeight: '31px',
        textAlign: 'center',
        letterSpacing: '-0.01em',
        color: '#1A194F',
        [theme.breakpoints.down('lg')]: {
          fontSize: 14,
        },
      },
      '& .line': {
        width: 151,
        border: '1px solid #1A194F',
        marginTop: 24,
        marginBottom: 24,
      },
      '& .name, & .position': {
        fontSize: 18,
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '-0.01em',
        color: '#1A194F',
      }
    },
    image: {
      width: 90,
      marginTop: 10,
    },
  }),
  { name: 'ImageCarousel' },
);

const comments = [
  {
    text: 'Pylink developed sophisticated data models for us that are the heart of our social entertainment discovery site. Miklos is that incredibly rare combination of "super smart" and "nothing is too much trouble", and Pylink remains a key part of our team whenever we have a complex problem that needs dealing with quickly and intelligently.',
    name: 'Greg Vartoukian',
    position: 'CEO',
    image: <Images.Watch />,
  },
  // {
  //   text: 'Lorem i1psum dolor sit amet, consectetur adipiscing elit. Curabitur id metus at nisi rutrum vulputate.',
  //   name: 'Sam 1Sample',
  //   position: 'CEO1 - Test Company',
  //   image: <Images.Watch />,
  // },
]

const ImageCarousel = () => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      setWidth(ref.current?.offsetWidth || 0);
    });
    setWidth(ref.current?.offsetWidth || 0);
  }, []);

  return (
    <div ref={ref} style={{ width: '100%' }} key={`image-${width}`}>
      <div className={classes.block}>
        <Typography variant="h2" className={classes.title}>Client reviews</Typography>
      </div>
      <Carousel
        infinite={false}
        centerMode
        updateOnItemClick
        hideNavIfAllVisible
        containerProps={{
          style: {
            width,
            justifyContent: "center",
          }
        }}
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: <Images.ArrowRight />,
          className: classes.actionBtn,
        }}
        backwardBtnProps={{
          children: <Images.ArrowLeft />,
          className: classes.actionBtn,
        }}
        itemsToScroll={0}
        itemsToShow={1}
        speed={400}
      >
        {comments.map((item) => (
          <div
            className={classes.commentCard}
            key={`image-${width}-${item.name}`}
          >
            <Images.Comment />
            <Typography className="comment">“{item.text}”</Typography>
            <div className="line" />
            <Typography className="name">{item.name}</Typography>
            <Typography className="position">{item.position}</Typography>
            <Box marginTop={1}>
              <Images.Watch className={classes.image} />
            </Box>
          </div>
        ))}
        <div key="empty" className={classes.commentCard} />
      </Carousel>
    </div>
  )
};

export default ImageCarousel;
