import React, { useCallback } from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Button from 'components/general/Button/Button';
import clsx from 'clsx';
import ROUTES from 'constants/routes';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: '#1A194F',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '38px',
        letterSpacing: '-0.02em',
      }
    },
    displayImage: {
      position: 'absolute',
      right: '13%',
      top: '20%',
      width: '32%',
    },
    block: {
      maxWidth: 745,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      }
    },
    mainBlockButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    },
    leftButton: {
      marginRight: 32,
    },
    line: {
      height: 2,
      width: 120,
      background: 'linear-gradient(180deg, rgba(234, 234, 234, 0) 0%, #EAEAEA 100%)',
    },
    verticalBlock: {
      fontSize: 8,
      lineHeight: '10px',
      textTransform: 'uppercase',
      letterSpacing: '0.7rem',
      fontWeight: 700,
    },
    topTextBlock: {
      transform: 'rotate(-90deg)',
      position: 'absolute',
      left: '-40%',
      top: '40%',
      display: 'flex',
      width: 300,
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    maxTopBoxWidth: {
      width: 745,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        width: '100%',
      }
    },
    customFontSize: {
      fontSize: 18,
      lineHeight: '28px',
    },
    actionsBlock: {
      display: 'flex',
      whiteSpace: 'nowrap',
      marginLeft: 15,
      marginTop: 32,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginLeft: 0,
        '& button:last-child': {
          marginTop: 16,
        }
      }
    }
  }),
  { name: 'IntroBlock' },
);

const Intro = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = useCallback(() => {
    window.open('https://calendly.com/miklos-lukacs', '_blank');
  }, []);

  const handleOurWorkClick = useCallback(() => {
    history.push(`/#${ROUTES.OUR_WORK}`);
  }, []);

  return (
    <Box width="100%">
      <div className={classes.block}>
        <Typography variant="h2" className={classes.title}>Data solutions</Typography>
        <Typography variant="h2" className={classes.title}>for your business success</Typography>
      </div>
      <Box display="flex" flexDirection={{ xs: 'column-reverse', md: 'row' }} justifyContent="space-between">
        <Box className={classes.maxTopBoxWidth}>
          <Typography className={classes.customFontSize}>
            We help you make better decisions by uncovering user behaviors, automating processes and predicting future demand
          </Typography>
        </Box>
        <Box className={classes.actionsBlock}>
          <Button buttonType="blueBtn" className={clsx(classes.mainBlockButton, classes.leftButton)} onClick={handleOurWorkClick}>
            See Our Work
          </Button>
          <Button buttonType="mainBtn" className={classes.mainBlockButton} onClick={handleClick}>
            Book a Call
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
