import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import toastr from 'toastr';
import PageLayout from 'components/general/PageLayout/PageLayout';
import {
  createStyles,
  Theme,
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Images from 'assets/img';
import pdf from 'assets/img/pdf.png';
import jpg from 'assets/img/jpg.png';
import xlsx from 'assets/img/xslx.png';
import unknown from 'assets/img/unknown.png';
import landingPage from 'services/api/LandingPageApi';
import { addFileInQueue, deleteFileFromQueue, useAppSettingsState } from 'context/appSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 400,
      overflow: 'hidden',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    list: {
      height: 'inherit',
      overflow: 'auto',
    },
    selected: {
      background: 'white'
    },
    doc: {
      '&:hover': {
        background: 'white',
      }
    },
    dnd: {
      width: '100%',
      height: 200,
      border: '1px dashed grey',
      background: 'white',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
);

type Folder = {
  expanded: boolean,
  isDirectory: boolean,
  objKey: string,
  objKeyHash: number,
  title: string,
  children: Folder[]
}

const TransactionList = () => {
  const classes = useStyles();
  const [data, setData] = useState<Folder[]>([]);
  const [selected, setSelected] = useState<Folder | null>(null);
  const ref = useRef<any>(null);

  const { appContext, appContextDispatch } = useAppSettingsState();

  useEffect(() => {
    landingPage.getVcsummary().then(resp => {
      setData(resp?.folderTree || [])
    })
  }, []);

  const handleSelect = useCallback((folder) => () => {
    setSelected(folder)
  }, []);

  const getImage = useCallback((title: string) => {
    const format = title.split('.')[1].toLowerCase();
    switch (format) {
      case 'pdf':  return pdf;
      case 'jpg':  return jpg;
      case 'xlsx':  return xlsx;

      default: return unknown;
    }
  }, []);

  const handleDownload = useCallback((objKey: string) => async() => {
    if (appContext.filesQueue.includes(objKey)) return;
    
    const files = [...appContext.filesQueue, objKey];
    appContextDispatch(addFileInQueue(files))

    await landingPage.getTransaction(objKey);

    appContextDispatch(
      deleteFileFromQueue(
        appContext.filesQueue
          .slice(appContext.filesQueue.findIndex(el => el === objKey), 1)
      )
    );
  }, [appContext.filesQueue]);

  const handleFileChange = useCallback((folderName: string) => async (e: any) => {
    if(e.target !== null) {
      await landingPage.addFiles(folderName, e.target.files);
      ref.current.value = "";
      toastr.success(`File uploaded.`);
    }
  }, []);

  return (
    <PageLayout displayGoBackButton>
      <Box display="flex" className={classes.root}>
        <Box height="inherit" width="50%">
          <List className={classes.list}>
            {
              data.map(folder => (
                <ListItem
                  className={clsx({
                    [classes.selected]: selected?.objKeyHash === folder.objKeyHash
                  })}
                  key={folder.objKeyHash}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Images.FolderIcon width={24} height={24} fill="white" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={folder.title}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={handleSelect(folder)}
                    >
                      <Images.LeftArrow style={{ transform: 'rotate(180deg)' }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Box>
        <Box height="inherit" width="50%">
          <List className={classes.list}>
            {selected && (
              <ListItem>
                <ListItemAvatar>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => ref?.current.click()}
                  >
                    <Images.DownloadIcon width={22} height={22} />
                  </IconButton>
                </ListItemAvatar>
                <ListItemText
                  primary="Upload New File"
                />
                <input
                  type="file"
                  ref={ref}
                  style={{ display: 'none' }}
                  accept=".pdf, .jpg, .xlsx"
                  onChange={handleFileChange(selected !== null ? selected.title : '')}
                />
              </ListItem>
            )}
            {
              selected && selected.children.map(file => (
                <ListItem className={classes.doc} key={file.objKeyHash}>
                  <ListItemAvatar>
                    <img src={getImage(file.title)} alt="pdf" width={24} height={24} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={file.title}
                  />
                  <ListItemSecondaryAction>
                    {
                      appContext.filesQueue.includes(file.objKey) ? (
                        <CircularProgress />
                      ) : (
                        <IconButton edge="end" aria-label="download" onClick={handleDownload(file.objKey)}>
                          <Images.DownloadIcon width={16} height={16} />
                        </IconButton>
                      )
                    }
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default TransactionList;
