/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: '#1A194F',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '38px',
        letterSpacing: '-0.02em',
      }
    },
    titleBlock: {
      marginBottom: 120,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      }
    },
    container: {
      height: 200,
      width: '100%',
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 50,
      position: "relative",
    },
    line: {
      position: 'absolute',
      width: 'calc(100% - 67px)',
      height: 1,
      background: '#BBBBBB',
      bottom: 30,
      left: 30,
      '&.active': {
        background: '#1A194F',
      }
    },
    block: {
      position: 'relative',
      width: 65,
    },
    block2: {
      position: 'relative',
      width: 76,
    },
    description: {
      background: '#1A194F',
      padding: '16px 32px',
      borderRadius: 5,
      maxWidth: 743,
      opacity: 0,
      transition: 'opacity 0.45s ease-out',
      position: 'absolute',
      bottom: 80,
      '& p': {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        color: 'white',
      },
      '&.active': {
        transition: 'opacity 0.45s ease-in',
        opacity: 1
      }
    },
    pointTitle: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: -70,
      
      '& p': {
        color: '#1A194F',
        fontSize: 20,
        lineHeight: '24px',
        textAlign: 'center',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          fontSize: 16,
          lineHeight: '20px',
        },

        '& svg': {
          marginLeft: 8,
        }
      },
      '&.active p': {
        color: '#1A194F',
      },
      '&.not-active p': {
        color: '#666',
      },
    },
    point1: {
      width: 65,
      height: 125,
      '& .upperCircle': {
        marginLeft: 7,
      },
      '& .vector': {
        marginLeft: 7,
      },
    },
    point2: {
      width: 65,
      height: 164,
      '& .upperCircle': {
        marginLeft: -7,
      },
      '& .vector': {
        marginLeft: -7,
      },
      '& .vector2': {
        marginTop: 18,
        marginLeft: -22.5,
      },
    },
    point3: {
      width: 76,
      height: 136,
      '& .upperCircle': {
        marginLeft: 0,
      },
      '& .vector': {
        marginLeft: 0,
      },
    },
    point4: {
      width: 65,
      height: 122,
      '& .circles': {
        marginBottom: -5,
      },
      '& .upperCircle': {
        marginLeft: 7,
      },
      '& .vector': {
        marginLeft: 7,
      },
      '& .vector2': {
        marginTop: 8,
        marginLeft: 20,
      },
    },
    point5: {
      width: 76,
      height: 124,
      '& .upperCircle': {
        marginLeft: 0,
      },
      '& .vector': {
        marginLeft: 0,
      },
    },
    point6: {
      width: 65,
      height: 194,
      '& .circles': {
        marginBottom: -5,
      },
      '& .upperCircle': {
        marginLeft: -7,
      },
      '& .vector': {
        marginLeft: -7,
      },
      '& .vector2': {
        marginTop: 27,
        marginLeft: -23,
      },
    },
    item: {
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      cursor: 'pointer',

      '& .upperCircle': {
        position: 'absolute',
      },
      '& .vector': {
        position: 'absolute',
      },
      '& .circles': {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
      },

      '& .fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#BBB',
      },
      '& .stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#BBB',
      },
    },
    point: {
      '& .point.fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#1A194F',
      },
      '& .point.stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#1A194F',
      },
    },
    active: {
      '& .fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#1A194F',
      },
      '& .stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#1A194F',
      },
    },
    label: {
      transition: 'opacity 0.45s ease-in',
      opacity: 1,
      zIndex: 5,

      '&.p1-1': {
        position: 'absolute',
        left: 90,
        bottom: 70,
      },
      '&.p1-2': {
        position: 'absolute',
        left: 90,
        bottom: -15,
      },

      '&.p2-1': {
        position: 'absolute',
        right: 80,
        bottom: 70,
      },
      '&.p2-2': {
        position: 'absolute',
        right: 80,
        bottom: -15,
      },
      '&.p2-3': {
        position: 'absolute',
        left: 80,
        bottom: 70,
        width: 122,
      },

      '&.p3-1': {
        position: 'absolute',
        right: 85,
        bottom: 90,
        width: 159
      },
      '&.p3-2': {
        position: 'absolute',
        left: 105,
        bottom: 50,
      },

      '&.p4-1': {
        position: 'absolute',
        left: -175,
        bottom: 85,
      },
      '&.p4-2': {
        position: 'absolute',
        left: 80,
        bottom: 52,
        width: 130,
      },
      '&.p4-3': {
        position: 'absolute',
        left: 80,
        bottom: -22,
      },

      '&.p5-1': {
        position: 'absolute',
        left: 100,
        bottom: 55,
        width: 110,
      },

      '&.p6-1': {
        position: 'absolute',
        left: -115,
        bottom: 115,
        width: 119,
      },
      '&.p6-2': {
        position: 'absolute',
        left: -170,
        bottom: 50,
        width: 107,
      },
      '&.p6-3': {
        position: 'absolute',
        left: -130,
        bottom: -50,
      },
      '&.p6-4': {
        position: 'absolute',
        left: -60,
        bottom: -110,
      },
    },
    connector: {
      transition: 'opacity 0.45s ease-in',
      opacity: 1,
      borderTop: '1px dashed #999',
      height: 1,
      position: 'absolute',
      zIndex: 0,

      '&.p1-1': {
        width: 80,
        bottom: 60,
        left: 25,
        transform: 'rotate(-45.53deg)',
      },
      '&.p1-2': {
        width: 70,
        bottom: 16,
        left: 26,
        transform: 'rotate(29.53deg)',
      },

      '&.p2-1': {
        width: 70,
        bottom: 58,
        right: 25,
        transform: 'rotate(45.53deg)',
      },
      '&.p2-2': {
        width: 70,
        bottom: 12,
        right: 25,
        transform: 'rotate(324deg)',
      },
      '&.p2-3': {
        width: 85,
        bottom: 70,
        right: -38,
        transform: 'rotate(125deg)',
      },

      '&.p3-1': {
        width: 90,
        bottom: 68,
        right: 18,
        transform: 'rotate(55deg)',
      },
      '&.p3-2': {
        width: 85,
        bottom: 49,
        left: 35,
        transform: 'rotate(151deg)',
      },

      '&.p4-1': {
        width: 85,
        bottom: 65,
        left: -31,
        transform: 'rotate(53deg)',
      },
      '&.p4-2': {
        width: 50,
        bottom: 50,
        left: 35,
        transform: 'rotate(145deg)',
      },
      '&.p4-3': {
        width: 50,
        bottom: 9,
        left: 35,
        transform: 'rotate(39deg)',
      },

      '&.p5-1': {
        width: 85,
        bottom: 57,
        left: 30,
        transform: 'rotate(144deg)',
      },

      '&.p6-1': {
        width: 110,
        bottom: 83,
        left: -39,
        transform: 'rotate(78deg)',
      },
      '&.p6-2': {
        width: 110,
        bottom: 50,
        left: -80,
        transform: 'rotate(19deg)',
      },
      '&.p6-3': {
        width: 85,
        bottom: -14,
        left: -39,
        transform: 'rotate(120deg)',
      },
      '&.p6-4': {
        width: 115,
        bottom: -14,
        left: -39,
        transform: 'rotate(104deg)',
      },
    },
    hidden: {
      opacity: 0,
      transition: 'opacity 0.45s ease-out',
    },
  }),
  { name: 'Points' },
);
