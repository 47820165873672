/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import Images from 'assets/img';
import clsx from 'clsx';
import { useStyles } from '../styles';

const POINT = 3;
const Point3 = ({ state, onPointEnter }: {
  state: number;
  onPointEnter: (number: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const isHidden = useMemo(() => state !== POINT, [state]);
  const getIconBtn = useMemo(() => {
    if (!isDownMd) return null;
    if (isHidden) {
      if (state !== -1) {
        return <Images.ShowBtn />
      }
      return <Images.ShowBtnActive />;
    }
    return <Images.ShowBtnActive />;
  }, [state, isDownMd]);

  return (
    <div className={classes.block2}>
      <div
        className={clsx(classes.item, classes.point3, {
            [classes.point]: state === -1,
            [classes.active]: !isHidden,
        })}
        onMouseEnter={isDownMd ? () => {} : onPointEnter(POINT)}
        onClick={!isDownMd ? () => {} : onPointEnter(POINT)}
        onMouseLeave={isDownMd ? () => {} : onPointEnter(-1)}
      >
        <div className={clsx(classes.pointTitle, {
            'active': !isHidden,
            'not-active': isHidden && state !== -1,
          })}
        >
          <Typography>Exploratory&nbsp;Data</Typography>
          <Typography>Analysis {getIconBtn}</Typography>
        </div>
        <Images.UpperCircle3 className='upperCircle' />
        <Images.Vector3 className='vector' />
        <Images.Circles3 className='circles' />
        <Images.P3_1 className={clsx(classes.label, 'p3-1', {
          [classes.hidden]: isHidden,
          })}
        />
        <div className={clsx(classes.connector, 'p3-1', {
            [classes.hidden]: isHidden,
          })}
        />
        <Images.P3_2 className={clsx(classes.label, 'p3-2', {
            [classes.hidden]: isHidden,
          })}
        />
        <div className={clsx(classes.connector, 'p3-2', {
            [classes.hidden]: isHidden,
          })}
        />
      </div>
    </div>
  );
}

export default Point3;
