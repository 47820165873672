import { Box, Typography, ClickAwayListener } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import Description from './Description';
import { useStyles } from './styles';

import Point1 from './points/point1';
import Point2 from './points/point2';
import Point3 from './points/point3';
import Point4 from './points/point4';
import Point5 from './points/point5';
import Point6 from './points/point6';

const Points = () => {
  const classes = useStyles();
  const [state, setState] = useState(-1);

  const onPointEnter = useCallback((number) => (e: any) => {
    e.stopPropagation();
    setState(number);
  }, []);

  return (
    <>
      <div className={classes.titleBlock}>
        <Typography variant="h2" className={classes.title}>
          End-to-end Data Science Solutions
        </Typography>
      </div>
      <Box height={450} position="relative">
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <ClickAwayListener onClickAway={() => setState(-1)}>
            <Box className={classes.container}>
              <div className={classes.line} />
              <Point1 state={state} onPointEnter={onPointEnter} />
              <Point2 state={state} onPointEnter={onPointEnter} />
              <Point3 state={state} onPointEnter={onPointEnter} />
              <Point4 state={state} onPointEnter={onPointEnter} />
              <Point5 state={state} onPointEnter={onPointEnter} />
              <Point6 state={state} onPointEnter={onPointEnter} />
            </Box>
          </ClickAwayListener>
          <Description state={state} />
        </Box>
      </Box>
    </>
  );
}

export default Points;
