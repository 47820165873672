import api from './BaseApiService';

const login = (data: {
  email: string;
  password: string;
}) => {
  
  return api({
    method: 'POST',
    url: '/login',
    data: {
      username: data.email,
      password: data.password
    }
  })
}

const logout = () => {
  return api({
    url: '/logout',
    method: 'POST',
  });
}

const auth =  {
  login,
  logout,
};

export default auth;

