import { Box, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

const Description = ({ state }: { state: number; }) => {
  const classes = useStyles();
 
  return (
    <>
      <Box className={clsx(classes.description, {
          'active': state === 1
        })}
      >
        <Typography>
          We ask the relevant questions and do additional research to build a solid understanding of your key challenges, processes and requirements in order to build the tech solution that best serves your business goals.
        </Typography>
      </Box>
      <Box className={clsx(classes.description, {
          'active': state === 2
        })}
      >
        <Typography>
          We gather internal and external data relevant to your business challenge, clean and maintain the dataset to making sure that it is informative, useful and always available for analysis.
        </Typography>
      </Box>
      <Box className={clsx(classes.description, {
          'active': state === 3
        })}
      >
        <Typography>
          We look for patterns understand trends and structure of the data to develop a comprehensive view and provide insights for the model.
        </Typography>
      </Box>
      <Box className={clsx(classes.description, {
          'active': state === 4
        })}
      >
        <Typography>
          We develop robust predictive, classification, time series forecasting and clustering models for any industries. Also, leveraging our quantitative finance background, we develop pricing, valuation and other cash flow models for complex financial products.
        </Typography>
      </Box>
      <Box className={clsx(classes.description, {
          'active': state === 5
        })}
      >
        <Typography>
          We transform data to visual stories in order to effectively communicate insights and results throughout your organisation.
        </Typography>
      </Box>
      <Box className={clsx(classes.description, {
          'active': state === 6
        })}
      >
        <Typography>
          By leveraging our cloud expertise, we automate your data pipeline, model recalibration and deployment, thus we transform all steps into a standalone end-to-end web-based solution.
        </Typography>
      </Box>
    </>
  );
}

export default Description;
