// eslint-disable-next-line import/prefer-default-export
export const cards = [
  {
    id: 1,
    tag: 'Case study',
    title: 'Find customers having the highest growth potential',
    description: 'Classification model for a manufacturer',
    images: [
      '/labels/Frame 122.png',
      '/labels/Frame 112.png',
      '/labels/Frame 120.png',
    ],
    link: '/article#rainshadow',
  },
  {
    id: 2,
    tag: 'Case study',
    title: 'Multiplying modelling speed with Pylink Waterfall',
    description: 'Software solution for building complex financial models in the securitisation space',
    images: [
      '/labels/Frame 120.png',
      '/labels/Frame 112.png',
      '/labels/Frame 121.png',
      '/labels/Frame 120 (1).png',
      '/labels/Frame 121 (1).png',
      '/labels/Frame 122 (1).png',
    ],
    link: '/article#pylinkWaterfallApp',
  },
  {
    id: 3,
    tag: 'Article',
    title: 'How to Build a Basic Python Cash Flow Model for a Loan',
    description: 'Python guide for financial experts',
    images: [
      '/labels/Frame 120.png',
    ],
    link: 'https://www.toptal.com/finance/cash-flow-consultants/python-cash-flow-model',
  },
];
