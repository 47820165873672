import api from './BaseApiService';

const getTransaction = (objKey: string) => {
  return new Promise(resolve => {
    const oReq = new XMLHttpRequest();
    oReq.open("GET", `${process.env.REACT_APP_BACKEND_BASE_URL}/api/landing-page/transaction?objKey=${objKey}`, true);
    oReq.setRequestHeader(
      "Authorization", `Bearer ${localStorage.getItem('token')}`
    );
    oReq.responseType = "blob";
    let filename = "";
    oReq.onload = () => {
      const file = oReq.response;
      const disposition = oReq.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
  
      const fileURL = URL.createObjectURL(file);
  
      if (filename) {
        const a = document.createElement("a");
  
        if (typeof a.download === 'undefined') {
          window.open(fileURL, '_blank');
        } else {
          a.href = fileURL;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.open(fileURL, '_blank');
      }
      resolve('');
    };
    oReq.send();
  });
}

const getVcsummary = () => {
  return api({
    method: 'GET',
    url: '/landing-page/vcsummary',
    api: true
  })
}

const addFiles = (folderName: string, files: string[]) => {
  const formData = new FormData();
  formData.append("folderName", folderName);
  formData.append("files", files[0]);

  return api({
    method: 'POST',
    url: '/landing-page/transaction',
    api: true,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

const landingPage =  {
  getTransaction,
  getVcsummary,
  addFiles,
};

export default landingPage;
