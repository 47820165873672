import React from 'react';
import { Link } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { LinkProps } from '@material-ui/core';
import clsx from 'clsx';

interface HashMenuLinkProps extends LinkProps {
  className?: string;
  to: string;
  active?: boolean;
}

const useStyles = makeStyles<Theme>(() => ({
  link: {
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 0px',
    color: 'white',
    boxSizing: 'border-box',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "'Silka', sans-serif",
    border: 'none',
    textDecoration: 'none',

    height: 'min-content',
    paddingBottom: 4,

    backgroundImage: 'linear-gradient(white, white)',
    backgroundSize: '0 2px, auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    transition: 'all .2s ease-out',

    '&:hover': {
      backgroundSize: '100% 2px, auto',
      color: 'white',
      backgroundColor: 'transparent',
    },
  },
  activeItem: {
    backgroundSize: '100% 2px, auto',
    color: 'white',
    backgroundColor: 'transparent',
  }
}), {
  name: 'HashMenuLink',
});

function HashMenuLink(props: HashMenuLinkProps) {
  const { children, active, className, ...other } = props;
  const classes = useStyles();

  return (
    <Link
      {...other}
      className={clsx({
      [classes.activeItem]: active,
    }, classes.link, className)}
    >
      {children}
    </Link>
  );
}

export default HashMenuLink;
