import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import marked from 'marked';
import { Box, makeStyles } from '@material-ui/core';
import PageLayout from 'components/general/PageLayout/PageLayout';

import './styles.css';
import { useLocation } from 'react-router-dom';
import ROUTES from 'constants/routes';

const policyText = require("./Policy.md");
const termsText = require("./Terms.md");

const useStyles = makeStyles(
  () => ({
    block: {
      width: '60%',
    },
    article: {
      textAlign: 'left'
    }
  }),
  { name: 'Privacy&Terms' }  
);

interface PrivacyTermsProps {
  terms?: boolean;
  privacy?: boolean;
}

const PrivacyTerms: FC<PrivacyTermsProps> = () => {
  const [text, setText] = useState('');
  const classes = useStyles();
  const location = useLocation();

  let textRequest = policyText.default;
  if(location.pathname.includes(ROUTES.TERMS)) {
    textRequest = termsText.default;
  }

  useEffect(() => {
    document.title = `Pylink Ltd. - ${location.pathname.includes(ROUTES.TERMS) ? 'Terms' : 'Privacy policy'}`;
  }, []);

  useEffect(() => {
    fetch(textRequest)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setText(marked(text));
      })
  }, []);

  return (
    <PageLayout displayGoBackButton>
      <Box className={clsx(classes.block, 'md-text-block')}>
        <section className={classes.article}>
          {/* eslint-disable-next-line react/no-danger */}
          <article dangerouslySetInnerHTML={{__html: text}} />
        </section>
      </Box>
    </PageLayout>
  )
}

export default PrivacyTerms;
