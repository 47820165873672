/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Images from 'assets/img';
import clsx from 'clsx';
import { useStyles } from '../styles';

const POINT = 3;
const Point3 = ({ state, onPointEnter }: {
  state: number;
  onPointEnter: (number: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const classes = useStyles();

  const isHidden = useMemo(() => state !== POINT, [state]);
  const getIconBtn = useMemo(() => {
    if (isHidden) {
      if (state !== -1) {
        return <Images.ShowBtn />
      }
      return <Images.ShowBtnActive />;
    }
    return <Images.ShowBtnActive />;
  }, [state]);

  return (
    <div className={clsx(classes.block2, 'p3')}>
      <div className={clsx(classes.bubble, 'p3', {
        'hidden': isHidden,
      })}
      >
        <div className="header">
          <div className="title">
            <Typography>Exploratory Data Analysis</Typography>
          </div>
          <div onClick={onPointEnter(-1)}>
            <Images.CloseIcon />
          </div>
        </div>
        <div className="content">
          <Typography>
            We look for patterns understand trends and structure of the data to develop a comprehensive view and provide insights for the model.
          </Typography>
        </div>
      </div>
      <div
        className={clsx(classes.item, classes.point3, {
            [classes.point]: state === -1,
            [classes.active]: !isHidden,
        })}
        onClick={onPointEnter(POINT)}
        // onMouseLeave={onPointEnter(-1)}
      >
        <div className={clsx(classes.circleBlock, 'p3')}>
          <Images.UpperCircle3 className='upperCircle' />
          <Images.Vector3 className='vector' height={51} />
          <Images.Circles3 className='circles' />
        </div>
        <div className={clsx(classes.pointTitle, {
            'active': !isHidden,
            'not-active': isHidden && state !== -1,
          })}
        >
          <Typography>Exploratory&nbsp;Data</Typography>
          <Typography className="labelWithIcon">Analysis {getIconBtn}</Typography>
        </div>
      </div>
      <Images.P3_1 className={clsx(classes.label, 'p3-1', {
        [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p3-1', {
          [classes.hidden]: false,
        })}
      />
      <Images.P3_2 className={clsx(classes.label, 'p3-2', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p3-2', {
          [classes.hidden]: false,
        })}
      />
    </div>
  );
}

export default Point3;
