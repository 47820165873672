/* eslint-disable import/prefer-default-export */

import img1 from './image1.png';
import img2 from './image2.png';
import img3 from './image3.png';

export const image: Record<string, string> = {
  img1,
  img2,
  img3,
};
