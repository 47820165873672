/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Images from 'assets/img';
import clsx from 'clsx';
import { useStyles } from '../styles';

const POINT = 2;
const Point2 = ({ state, onPointEnter }: {
  state: number;
  onPointEnter: (number: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const classes = useStyles();

  const isHidden = useMemo(() => state !== POINT, [state]);
  const getIconBtn = useMemo(() => {
    if (isHidden) {
      if (state !== -1) {
        return <Images.ShowBtn />
      }
      return <Images.ShowBtnActive />;
    }
    return <Images.ShowBtnActive />;
  }, [state]);

  return (
    <div className={clsx(classes.block, 'p2')}>
      <div className={clsx(classes.bubble, 'p2', {
        'hidden': isHidden,
      })}
      >
        <div className="header">
          <div className="title">
            <Typography>Data Preparation</Typography>
          </div>
          <div onClick={onPointEnter(-1)}>
            <Images.CloseIcon />
          </div>
        </div>
        <div className="content">
          <Typography>
            We gather internal and external data relevant to your business challenge, clean and maintain the dataset to making sure that it is informative, useful and always available for analysis.
          </Typography>
        </div>
      </div>
      <div
        className={clsx(classes.item, classes.point2, {
            [classes.point]: state === -1,
            [classes.active]: !isHidden,
        })}
        onClick={onPointEnter(2)}
        // onMouseLeave={onPointEnter(-1)}
      >
        <div className={clsx(classes.circleBlock, 'p2')}>
          <Images.UpperCircle2 className='upperCircle' />
          <Images.Vector2 className='vector' height={65} />
          <Images.Vector22 className='vector2' height={39} />
          <Images.Circles2 className='circles' />
        </div>
        <div className={clsx(classes.pointTitle, {
            'active': !isHidden,
            'not-active': isHidden && state !== -1,
          })}
        >
          <Typography className="labelWithIcon">Data Preparation {getIconBtn}</Typography>
        </div>
      </div>
      <Images.P2_1 className={clsx(classes.label, 'p2-1', {
        [classes.hidden]: false,
      })}
      />
      <div className={clsx(classes.connector, 'p2-1', {
          [classes.hidden]: false,
        })}
      />
      <Images.P2_2 className={clsx(classes.label, 'p2-2', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p2-2', {
          [classes.hidden]: false,
        })}
      />
      <Images.P2_3 className={clsx(classes.label, 'p2-3', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p2-3', {
          [classes.hidden]: false,
        })}
      />
    </div>
  );
}

export default Point2;
