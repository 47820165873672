import React, { useState } from 'react';
import {
  Formik, Form
} from 'formik';
import * as yup from 'yup';
import { FieldType } from 'components/ContactUsPage/Reducer';
import support from 'services/api/SupportApi';
import { 
  Alert
} from '@material-ui/lab';
import StepperAction from './StepperAction';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const schema = yup.object().shape({
  [FieldType.Name]: yup.string().label('Your name').min(3).required().max(30).trim(),
  [FieldType.Company]: yup.string().label('Company').min(3).required().max(30).trim(),
  [FieldType.Phone]: yup.string().matches(phoneRegExp, 'Phone number is not valid').required().trim(),
  [FieldType.Topic]: yup.string().label('Topic').min(3).required().max(30).trim(),
  [FieldType.Email]: yup.string().lowercase().trim().label('Email').email('Invalid email format').required().max(50),
  [FieldType.Message]: yup.string().min(3),
});

const Stepper = () => {
  const [isSend, setIsSend] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  return (
    <Formik
      initialValues={{ 
        [FieldType.Name]: '',
        [FieldType.Company]: '',
        [FieldType.Email]: '',
        [FieldType.Phone]: '',
        [FieldType.Topic]: '',
        [FieldType.Message]: '',
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        support.sendContactUsForm(values)
          .then(() => setIsSend(true))
          .catch((error) => setErrorMessage(error.message || 'Some error with request'));
      }}
    >
      {() => (
        <Form style={{ height: 280 }}>
          {!!errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {isSend ? (
            <Alert severity="success">Your message successfuly sent!</Alert>
            ) : 
            <StepperAction />}
        </Form>
       )}
    </Formik>
  )
}

export default Stepper;
