import { createMuiTheme } from '@material-ui/core/styles';
import colors from 'constants/colors';

const theme = createMuiTheme({
  typography: {
    body1: {
      fontFamily: 'Silka',
    },
    h2: {
      fontSize: 48,
      fontWeight: 'bold',
      fontFamily: 'Silka',
      lineHeight: '58px',
      letterSpacing: '-0.01em',
    },
  },
  palette: {
    primary: {
      main: colors.accent,
    },
    secondary: {
      main: colors.darkBlue,
    },
    info: {
      main: colors.white,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontFamily: 'BasierCircle',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        fontStyle: 'italic',
      },
    },
    MuiTypography: {
      paragraph: {
        whiteSpace: 'pre-line',
      },
    },
  },
});

export default theme;
