/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Images from 'assets/img';
import clsx from 'clsx';
import { useStyles } from '../styles';

const POINT = 6;
const Point6 = ({ state, onPointEnter }: {
  state: number;
  onPointEnter: (number: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const classes = useStyles();

  const isHidden = useMemo(() => state !== POINT, [state]);
  const getIconBtn = useMemo(() => {
    if (isHidden) {
      if (state !== -1) {
        return <Images.ShowBtn />
      }
      return <Images.ShowBtnActive />;
    }
    return <Images.ShowBtnActive />;
  }, [state]);

  return (
    <div className={clsx(classes.block, 'p6')}>
      <div className={clsx(classes.bubble, 'p6', {
        'hidden': isHidden,
      })}
      >
        <div className="header">
          <div className="title">
            <Typography>Deployment</Typography>
          </div>
          <div onClick={onPointEnter(-1)}>
            <Images.CloseIcon />
          </div>
        </div>
        <div className="content">
          <Typography>
            By leveraging our cloud expertise, we automate your data pipeline, model recalibration and deployment, thus we transform all steps into a standalone end-to-end web-based solution.
          </Typography>
        </div>
      </div>
      <div
        className={clsx(classes.item, classes.point6, {
            [classes.point]: state === -1,
            [classes.active]: !isHidden,
        })}
        onClick={onPointEnter(POINT)}
        // onMouseLeave={onPointEnter(-1)}
      >
        <div className={clsx(classes.circleBlock, 'p4')}>
          <Images.UpperCircle6 className='upperCircle' />
          <Images.Vector6 className='vector' height={51}  />
          <Images.Circles6 className='circles' />
        </div>
        <div className={clsx(classes.pointTitle, {
            'active': !isHidden,
            'not-active': isHidden && state !== -1,
          })}
        >
          <Typography className="labelWithIcon">Deployment {getIconBtn}</Typography>
        </div>
      </div>
      <Images.P6_1 className={clsx(classes.label, 'p6-1', {
        [classes.hidden]: false,
      })}
      />
      <div className={clsx(classes.connector, 'p6-1', {
          [classes.hidden]: false,
        })}
      />
      <Images.P6_2 className={clsx(classes.label, 'p6-2', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p6-2', {
          [classes.hidden]: false,
        })}
      />
      <Images.P6_3 className={clsx(classes.label, 'p6-3', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p6-3', {
          [classes.hidden]: false,
        })}
      />
      <Images.P6_4 className={clsx(classes.label, 'p6-4', {
          [classes.hidden]: false,
        })}
      />
      <div className={clsx(classes.connector, 'p6-4', {
          [classes.hidden]: false,
        })}
      />
    </div>
  );
}

export default Point6;
