import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Slide, IconButton, Box, Divider } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { setCurrentSection, useAppSettingsState } from 'context/appSettings';
import Images from 'assets/img';
import colors from 'constants/colors';
import ROUTES from 'constants/routes';
import clsx from 'clsx';
import HashMenuLink from '../HashMenuLink/HashMenuLink';

import Button from '../Button/Button';

type HeaderBackground = {
  headerBackground: string;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    hamburger: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      }
    },
    menu: {
      width: '100%',
      height: '100vh',
      background: colors.darkBlue,
      position: 'fixed',
      top: 0,
      left: 0,
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingTop: 50,
      paddingBottom: 10,
    },
    topSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    item: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: '29px',
      letterSpacing: '-0.02em',
      textTransform: 'capitalize',
      color: '#FFFFFF',
    },
    links: {
      maxHeight: 420,
      height: `100%`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 30,
      paddingBottom: 30,
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      minHeight: 200,
    },
    iconLock: {
      marginRight: theme.spacing(2),
    },
    content: {
      maxHeight: 600,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  }),
  { name: 'Header' },
);

function MobileMenu() {
  const classes = useStyles();
  const history = useHistory();
  const [checked, setChecked] = useState(false);

  const { appContext, appContextDispatch } = useAppSettingsState();

  const goToPage = useCallback((page) => history.push(page), []);

  const handleActionClick = useCallback(() => {
    setChecked(item => !item)
  }, []);

  const handleClick = useCallback((route: string) => () => {
    appContextDispatch(setCurrentSection(route));
    handleActionClick();
  }, []);

  return (
    <>
      <IconButton onClick={handleActionClick} className={classes.hamburger}>
        <Images.HamburgerIcon />
      </IconButton>
      <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
        <Box className={classes.menu}>
          <Box className={classes.content}>
            <Box className={classes.topSection}>
              <Images.LogoBigger />
              <IconButton onClick={handleActionClick}>
                <Images.CloseMenuIcon />
              </IconButton>
            </Box>
            <Box className={classes.links}>
              <HashMenuLink
                className={classes.item}
                to={`/${ROUTES.HOME_HASH}`}
                onClick={handleClick(ROUTES.HOME_HASH)}
                active={appContext.currentSection === ROUTES.HOME_HASH}
              >
                Home
              </HashMenuLink>
              <HashMenuLink
                className={classes.item}
                to={`/${ROUTES.OUR_SERVICE}`}
                onClick={handleClick(ROUTES.OUR_SERVICE)}
                active={appContext.currentSection === ROUTES.OUR_SERVICE}
              >
                Our Service
              </HashMenuLink>
              <HashMenuLink
                className={classes.item}
                to={`/${ROUTES.OUR_WORK}`}
                onClick={handleClick(ROUTES.OUR_WORK)}
                active={appContext.currentSection === ROUTES.OUR_WORK}
              >
                Our Work
              </HashMenuLink>
              <HashMenuLink
                className={classes.item}
                to={`/${ROUTES.CLIENT_REVIEW}`}
                onClick={handleClick(ROUTES.CLIENT_REVIEW)}
                active={appContext.currentSection === ROUTES.CLIENT_REVIEW}
              >
                Client Reviews
              </HashMenuLink>
              <Button
                className={classes.item}
                buttonType="secondaryBtn"
                onClick={() => goToPage(ROUTES.CONTACT)}
              >
                Contact
              </Button>
            </Box>
            {false && (
              <Box display="flex" justifyContent="center">
                <IconButton className={clsx(classes.item)}>
                  <Images.LockIcon className={classes.iconLock} />Login
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Slide>
    </>
  );
}

export default MobileMenu;
