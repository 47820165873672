/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: '#1A194F',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '38px',
        letterSpacing: '-0.02em',
      }
    },
    titleBlock: {
      marginBottom: 120,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      }
    },
    container: {
      height: '100%',
      width: '100%',
      display: "flex",
      marginBottom: 250,
      position: "relative",
      flexDirection: 'column',
    },
    line: {
      position: 'absolute',
      height: 915,
      width: 1,
      background: '#BBBBBB',
      top: 17,
      left: 13.5,
      '&.active': {
        background: '#1A194F',
      }
    },
    block: {
      position: 'relative',
      '&.p2': {
        top: 125,
      },
      '&.p4': {
        top: 580,
      },
      '&.p6': {
        top: 915,
      },
    },
    block2: {
      position: 'relative',
      width: 76,
      '&.p3': {
        top: 366,
      },
      '&.p5': {
        top: 785,
      },
      
    },
    description: {
      background: '#1A194F',
      padding: '16px 32px',
      borderRadius: 5,
      maxWidth: 743,
      opacity: 0,
      transition: 'opacity 0.45s ease-out',
      position: 'absolute',
      bottom: 80,
      '& p': {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        color: 'white',
      },
      '&.active': {
        transition: 'opacity 0.45s ease-in',
        opacity: 1
      }
    },
    circleBlock: {
      transform: 'rotate(90deg)',
      display: 'flex',
      justifyContent: 'center',
      width: 32,
      height: 32,
      '&.p1': {
      },
      '&.p2': {
      },
      '&.p4': {
        '& .circles': {
          top: 3,
        }
      }
    },
    pointTitle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      left: 100,
      position: 'absolute',

      '& p': {
        color: '#1A194F',
        fontSize: 16,
        lineHeight: '19px',
        textAlign: 'left',
        fontWeight: 'bold',
      },
      '& .labelWithIcon': {
        whiteSpace: 'nowrap',
        display: 'flex',
        '& svg': {
          marginLeft: 4,
        }
      },
      '&.active p': {
        color: '#1A194F',
      },
      '&.not-active p': {
        color: '#666',
      },
    },
    point1: {
      '& .upperCircle': {
        marginLeft: 3,
        bottom: 55,
      },
      '& .vector': {
        marginLeft: 3,
        bottom: 15,
      },
    },
    point2: {
      '& .upperCircle': {
        marginLeft: -3,
        bottom: 75,
      },
      '& .vector': {
        marginLeft: -3,
        bottom: 15,
      },
      '& .vector2': {
        marginTop: 18,
        marginLeft: -10.5,
        bottom: 35,
        position: 'absolute',
      },
    },
    point3: {
      '& .upperCircle': {
        marginLeft: 0,
        bottom: 65,
      },
      '& .vector': {
        marginLeft: 0,
        bottom: 15,
      },
    },
    point4: {
      '& .circles': {
        marginBottom: -5,
      },
      '& .upperCircle': {
        marginLeft: 3,
        bottom: 55,
      },
      '& .vector': {
        marginLeft: 3,
        bottom: 15,
      },
      '& .vector2': {
        marginTop: 8,
        marginLeft: 10,
        bottom: 30,
        position: 'absolute',
      },
    },
    point5: {
      '& .upperCircle': {
        marginLeft: 0,
        bottom: 60,
      },
      '& .vector': {
        marginLeft: 0,
        bottom: 15,
      },
    },
    point6: {
      '& .circles': {
        marginBottom: -5,
      },
      '& .upperCircle': {
        marginLeft: -3,
        bottom: 65,
      },
      '& .vector': {
        marginLeft: -3,
        bottom: 15,
      },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      zIndex: 2,
      // maxWidth: 300,
      // justifyContent: 'space-between',

      '& .upperCircle': {
        position: 'absolute',
        '&.p1': {
        }
      },
      '& .vector': {
        position: 'absolute',
        '&.p1': {
        }
      },
      '& .circles': {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        width: 32,
        height: 32,
      },

      '& .fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#BBB',
      },
      '& .stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#BBB',
      },
    },
    point: {
      '& .point.fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#1A194F',
      },
      '& .point.stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#1A194F',
      },
    },
    active: {
      '& .fill': {
        transition: 'all 0.25s ease-in-out',
        fill: '#1A194F',
      },
      '& .stroke': {
        transition: 'all 0.25s ease-in-out',
        stroke: '#1A194F',
      },
    },
    label: {
      transition: 'opacity 0.45s ease-in',
      opacity: 1,
      zIndex: 1,

      '&.p1-1': {
        position: 'absolute',
        left: 100,
        bottom: -75,
        height: 25,
        width: 78,
      },
      '&.p1-2': {
        position: 'absolute',
        left: 100,
        bottom: -115,
        height: 25,
        width: 78,
      },

      '&.p2-1': {
        position: 'absolute',
        left: 100,
        bottom: -85,
      },
      '&.p2-2': {
        position: 'absolute',
        left: 100,
        bottom: -130,
      },
      '&.p2-3': {
        position: 'absolute',
        left: 100,
        bottom: -215,
        width: 122,
      },

      '&.p3-1': {
        position: 'absolute',
        left: 100,
        bottom: -105,
        width: 159
      },
      '&.p3-2': {
        position: 'absolute',
        left: 100,
        bottom: -175,
      },

      '&.p4-1': {
        position: 'absolute',
        left: 100,
        bottom: -70,
      },
      '&.p4-2': {
        position: 'absolute',
        left: 100,
        bottom: -140,
        width: 130,
      },
      '&.p4-3': {
        position: 'absolute',
        left: 100,
        bottom: -185,
      },

      '&.p5-1': {
        position: 'absolute',
        left: 100,
        bottom: -105,
        width: 110,
      },

      '&.p6-1': {
        position: 'absolute',
        left: 100,
        bottom: -115,
        width: 119,
      },
      '&.p6-2': {
        position: 'absolute',
        left: 100,
        bottom: -180,
        width: 107,
      },
      '&.p6-3': {
        position: 'absolute',
        left: 100,
        bottom: -240,
      },
      '&.p6-4': {
        position: 'absolute',
        left: 100,
        bottom: -300,
      },
    },
    connector: {
      transition: 'opacity 0.45s ease-in',
      opacity: 1,
      borderTop: '1px dashed #999',
      height: 1,
      position: 'absolute',
      zIndex: 0,

      '&.p1-1': {
        width: 95,
        bottom: -40,
        left: 15,
        transform: 'rotate(24deg)',
      },
      '&.p1-2': {
        width: 115,
        bottom: -60,
        left: 1,
        transform: 'rotate(42deg)',
      },

      '&.p2-1': {
        width: 102,
        bottom: -42,
        left: 5,
        transform: 'rotate(33deg)',
      },
      '&.p2-2': {
        width: 130,
        bottom: -61,
        left: -10,
        transform: 'rotate(48deg)',
      },
      '&.p2-3': {
        width: 200,
        bottom: -100,
        left: -45,
        transform: 'rotate(64deg)',
      },

      '&.p3-1': {
        width: 130,
        bottom: -61,
        left: 0,
        transform: 'rotate(42deg)',
      },
      '&.p3-2': {
        width: 170,
        bottom: -93,
        left: -25,
        transform: 'rotate(59deg)',
      },

      '&.p4-1': {
        width: 95,
        bottom: -38,
        left: 10,
        transform: 'rotate(25deg)',
      },
      '&.p4-2': {
        width: 130,
        bottom: -72,
        left: -7,
        transform: 'rotate(52deg)',
      },
      '&.p4-3': {
        width: 180,
        bottom: -100,
        left: -30,
        transform: 'rotate(61deg)',
      },

      '&.p5-1': {
        width: 112,
        bottom: -51,
        left: 2,
        transform: 'rotate(38deg)',
      },

      '&.p6-1': {
        width: 140,
        bottom: -60,
        left: -3,
        transform: 'rotate(40deg)',
      },
      '&.p6-2': {
        width: 175,
        bottom: -89,
        left: -31,
        transform: 'rotate(60deg)',
      },
      '&.p6-3': {
        width: 240,
        bottom: -125,
        left: -59,
        transform: 'rotate(67deg)',
      },
      '&.p6-4': {
        width: 292,
        bottom: -158,
        left: -83,
        transform: 'rotate(71deg)',
      },
    },
    hidden: {
      opacity: 0,
      transition: 'opacity 0.45s ease-out',
    },

    bubble: {
      position: 'absolute',
      padding: 16,
      width: 300,
      background: '#1A194F',
      borderRadius: 20,
      zIndex: 10,
      transition: 'all 0.25s ease-in-out',

      '&.hidden': {
        opacity: 0,
        zIndex: 0,
        transition: 'all 0.25s ease-in-out',
      },
      '& .header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& path': {
          stroke: 'white',
        },

        '& .title': {
          '& p': {
            fontFamily: 'Silka',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '19px',
            letterSpacing: '-0.02em',
          }
        },
      },
      '& .content': {
        marginTop: 16,
        '& p': {
          fontFamily: 'Silka',
          color: 'white',
          fontWeight: 'normal',
          fontSize: 14,
          lineHeight: '16px',
          letterSpacing: '-0.02em',
        }
      },

      '&::after': {
        content: '" "',
        position: 'absolute',
        bottom: 0,
        width: 0,
        height: 0,
        border: '24px solid transparent',
        borderTopColor: '#1A194F',
        borderBottom: 0,
        marginLeft: -24,
        marginBottom: -24,
      },
      '&.p1': {
        top: -195,

        '&::after': {
          left: 233,
        },
      },
      '&.p2': {
        top: -195,

        '&::after': {
          left: 255,
        },
      },
      '&.p3': {
        top: -180,

        '&::after': {
          left: 180,
        },
      },
      '&.p4': {
        top: -225,
        width: 320,

        '&::after': {
          left: 277,
        },
      },
      '&.p5': {
        top: -180,
        width: 310,

        '&::after': {
          left: 262,
        },
      },
      '&.p6': {
        top: -195,

        '&::after': {
          left: 212,
        },
      },
    }
  }),
  { name: 'Points' },
);
