import React, { useCallback, useState } from 'react';
import Button from 'components/general/Button/Button';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '../../components/ContactUsPage/Stepper/Stepper';

const useStyles = makeStyles(
  (theme) => ({
    mainBlockButton: {
      marginTop: 24,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    }
  }),
  {
    name: 'ContactUsContent',
  },
);

const Content = () => {
  const classes = useStyles();
  const [showStepper, setShowStepper] = useState(false);

  const handleClick = useCallback(() => {
    window.open('https://calendly.com/miklos-lukacs', '_blank');
  }, []);

  const handleWriteToUsClick = useCallback(() => {
    setShowStepper(true);
  }, []);

  return (
    <>
      <Box marginTop={5}>
        <Typography>Book a call with us easily to get to help us understand your challenges better</Typography>
        <Button buttonType="mainBtn" className={classes.mainBlockButton} onClick={handleClick}>
          Book a Call
        </Button>
      </Box>
      <Box mt={7}>
        {!showStepper ? (
          <Box paddingTop={5} height={280}>
            <Typography>Or get in touch with us via e-mail</Typography>
            <Button buttonType="blueBtn" className={classes.mainBlockButton} onClick={handleWriteToUsClick}>
              Write To Us
            </Button>
          </Box>
        ) : (
          <Stepper />
        )}
      </Box>
    </>
  );
};

export default Content;
