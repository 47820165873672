import React, { useState, useCallback, useEffect } from 'react';
import { Box, FormControlLabel, Checkbox, Link, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import {
  setName,
  setCompany,
  setEmail,
  setPhone,
  setTopic,
  setMessage,
  setEditField,
} from 'components/ContactUsPage/Reducer/Actions';
import { useStore } from 'components/ContactUsPage/context';
import Button from 'components/general/Button/Button';
import colors from 'constants/colors';
import { FieldType } from 'components/ContactUsPage/Reducer';
import InputText from 'components/general/InputText/InputText';
import clsx from 'clsx';

const defaultHelperText = "Hit enter when done";

const useStyles = makeStyles(
  (theme) => ({
    submit: {
      color: colors.darkBlue,
      fontWeight: 700,
      border: `1px solid ${colors.darkBlue}`,
    },
    agreements: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '-0.02em',
      color: colors.textDefault,
      maxWidth: 263,
    },
    hidden: {
      display: 'none',
    }
  }),
  {
    name: 'StepperAction',
  }
);

const stepsDescription = {
  [FieldType.Name]: 0,
  [FieldType.Company]: 1,
  [FieldType.Email]: 2,
  [FieldType.Phone]: 3,
  [FieldType.Topic]: 4,
  [FieldType.Message]: 5,
  'Actions': 6,
};

const StepperAction = () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const { store, storeDispatch } = useStore();

  useEffect(() => {
    const selectedField = store.fieldName;
    if(selectedField !== null) {
      setStep(stepsDescription[selectedField]);
    }
  }, [store.fieldName, setStep]);

  const keyPress = useCallback((saveValueAction, nextStep) => (e: any) => {
    if (!e.shiftKey && e.keyCode === 13) {
      setStep(nextStep);
      if (store.fieldName) {
        storeDispatch(setEditField(null));
      }
      storeDispatch(saveValueAction(e.target.value));
    }
  }, [step, setStep]);

  const handleChange = useCallback((e, isChecked) => setIsPolicyChecked(isChecked), []);

  return (
    <>
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Name] })}
        component={InputText}
        name={FieldType.Name}
        label="Your name"
        autoFocus
        helperText={defaultHelperText}
        keyPress={keyPress(setName, stepsDescription[FieldType.Company])}
      />
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Company] })}
        component={InputText}
        name={FieldType.Company}
        label="Company"
        autoFocus
        helperText={defaultHelperText}
        keyPress={keyPress(setCompany, stepsDescription[FieldType.Email])}
      />
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Email] })}
        component={InputText}
        type="email"
        name={FieldType.Email}
        label="Your email"
        autoFocus
        helperText={defaultHelperText}
        keyPress={keyPress(setEmail, stepsDescription[FieldType.Phone])}
      />
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Phone] })}
        component={InputText}
        name={FieldType.Phone}
        label="Your Phone"
        autoFocus
        helperText={defaultHelperText}
        keyPress={keyPress(setPhone, stepsDescription[FieldType.Topic])}
      />
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Topic] })}
        component={InputText}
        name={FieldType.Topic}
        label="Topic"
        autoFocus
        helperText={defaultHelperText}
        keyPress={keyPress(setTopic, stepsDescription[FieldType.Message])}
      />
      <Field
        className={clsx({[classes.hidden]: step !== stepsDescription[FieldType.Message] })}
        component={InputText}
        name={FieldType.Message}
        type="textarea"
        autoFocus
        label="Your message"
        helperText={`Hit Shift + Enter for new line. ${defaultHelperText}`}
        keyPress={keyPress(setMessage, stepsDescription.Actions)}
      />
      {(step === 6) && (
        <Box className={clsx({[classes.hidden]: step !== stepsDescription.Actions })}>
          <Button
            type="submit"
            buttonType="primaryBtn"
            className={classes.submit}
            disabled={!isPolicyChecked}
          >
            Send Now
          </Button>
          <Box mt={3}>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={handleChange}
                  name="isCheckedPolicy"
                  color="primary"
                />
              )}
              label={(
                <div className={classes.agreements}>
                  I acknowledge that I have read and agree to Pylink’s <Link href='/privacy'>Privacy Policy</Link> and <Link href='/terms'>Terms & Conditions</Link>.
                </div>
              )}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default StepperAction;
