import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import colors from 'constants/colors';
import { useStore } from 'components/ContactUsPage/context';
import { setEditField } from 'components/ContactUsPage/Reducer/Actions';
import { FieldType } from 'components/ContactUsPage/Reducer';
import Images from 'assets/img';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      fontFamily: 'Basier Square',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '-0.02em',
      color: colors.backgroundDark,
      width: 'fit-content',
    },
    value: {
      fontFamily: 'Basier Square',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: '-0.02em',
      color: colors.backgroundDark,
    },
    message: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },
    outputSection: {
      '&:hover': {
        '& button': {
          display: 'inline-flex',
        },
      },
    },
    block: {
      position: 'relative',
      width: 'fit-content',
    },
    editIcon: {
      position: 'absolute',
      top: -14,
      right: -40,
      display: 'none',
    },
  }),
  {
    name: 'FromData',
  },
);

const steps: Record<FieldType, string> = {
  [FieldType.Name]: 'Your name',
  [FieldType.Company]: 'Company',
  [FieldType.Email]: 'Your email',
  [FieldType.Phone]: 'Your phone',
  [FieldType.Topic]: 'Topic',
  [FieldType.Message]: 'Your message',
};

const FormData = () => {
  const classes = useStyles();
  const { store: { inputs }, storeDispatch } = useStore();

  const handleClick = useCallback((fieldName: FieldType) => {
    storeDispatch(setEditField(fieldName));
  }, []);

  return (
    <Box>
      {
        Object.entries(inputs).map(([key, value], index) => (
          (value && steps[key as FieldType]) ? (
            <Box className={classes.outputSection} mt={index === 0 ? 0 : 3} key={`form-data-${key}`}>
              <Box className={classes.block}>
                <Typography className={classes.title}>{steps[key as FieldType]}</Typography>
                <IconButton className={classes.editIcon} onClick={() => handleClick(key as FieldType)}>
                  <Images.EditIcon />
                </IconButton>
              </Box>
              <Typography
                paragraph
                className={clsx(classes.value, { [classes.message]: key === FieldType.Message })}
              >
                {value}
              </Typography>
            </Box>
          ) : null
        ))
      }
    </Box>
  )
}

export default FormData;
