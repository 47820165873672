enum ROUTES {
  HOME = '/',
  HOME_HASH = 'home',
  PRODUCT = '#product',
  CONSULTING = '#consulting',
  LOGIN = '/login',
  PRIVACY = '/privacy',
  TERMS = '/terms',
  CONTACT = '/contact',

  OUR_SERVICE = 'our_service',
  OUR_WORK = 'our_work',
  CLIENT_REVIEW = 'client_reviews',
}

export const HOME_LINKS = ROUTES.HOME || ROUTES.PRODUCT || ROUTES.CONSULTING;

export const USER_TRANSACRION_LIST = '/user-transactions';
export const USER_TRANSACRION = '/user-transactions/:name';

export default ROUTES;
