import bespokeConsulting from './bespokeConsulting.png';
import morningstar from './morningstar.png';
import bank from './bank.png';
import morningstarUser1 from './morningstarUser1.png';
import morningstarUser2 from './morningstarUser2.png';
import morningstarUser3 from './morningstarUser3.png';
import bankUser from './bankUser.png';
import imageDisplay from './prodisplay.png';
import product1 from './product1.png';
import product2 from './product2.png';
import product3 from './product3.png';
import shots from './shots.png';
import testimonialheadshots from './testimonialheadshots.png';

import { ReactComponent as Dot } from './kor.svg';
import { ReactComponent as LogoBigger } from './logo-bigger.svg';
import { ReactComponent as LogoSmaller } from './logo-smaller.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import { ReactComponent as CloseIcon } from './closeIcon.svg';
import { ReactComponent as EditIcon } from './editIcon.svg';
import { ReactComponent as HamburgerIcon } from './hamburger.svg';
import { ReactComponent as CloseMenuIcon } from './closeMenu.svg';
import { ReactComponent as LockIcon } from './lock.svg';
import { ReactComponent as MarkIcon } from './mark.svg';
import { ReactComponent as FolderIcon } from './folder.svg';
import { ReactComponent as DownloadIcon } from './download.svg';
import { ReactComponent as GitHub } from './github.svg';

import { ReactComponent as MorningStar } from './morningstar.svg';
import { ReactComponent as Axis } from './axis.svg';
import { ReactComponent as Watch } from './watch.svg';
import { ReactComponent as ArrowLeft } from './arrowLeft.svg';
import { ReactComponent as ArrowRight } from './arrowRight.svg';
import { ReactComponent as Comment} from './comment.svg';
import { ReactComponent as MorningStart} from './morningstart.svg';

import { ReactComponent as Circles } from './prepared/point1/circles.svg';
import { ReactComponent as Vector } from './prepared/point1/Vector.svg';
import { ReactComponent as UpperCircle } from './prepared/point1/uppercircle.svg';
import { ReactComponent as P1_1 } from './prepared/point1/p1-1.svg';
import { ReactComponent as P1_2 } from './prepared/point1/p1-2.svg';

import { ReactComponent as Circles2 } from './prepared/point2/circles.svg';
import { ReactComponent as Vector2 } from './prepared/point2/Vector.svg';
import { ReactComponent as Vector22 } from './prepared/point2/Vector2.svg';
import { ReactComponent as UpperCircle2 } from './prepared/point2/uppercircle.svg';
import { ReactComponent as P2_1 } from './prepared/point2/p2-1.svg';
import { ReactComponent as P2_2 } from './prepared/point2/p2-2.svg';
import { ReactComponent as P2_3 } from './prepared/point2/p2-3.svg';

import { ReactComponent as Circles3 } from './prepared/point3/circles.svg';
import { ReactComponent as Vector3 } from './prepared/point3/Vector.svg';
import { ReactComponent as UpperCircle3 } from './prepared/point3/uppercircle.svg';
import { ReactComponent as P3_1 } from './prepared/point3/p3-1.svg';
import { ReactComponent as P3_2 } from './prepared/point3/p3-2.svg';

import { ReactComponent as Circles4 } from './prepared/point4/circles.svg';
import { ReactComponent as Vector4 } from './prepared/point4/Vector.svg';
import { ReactComponent as Vector42 } from './prepared/point4/Vector2.svg';
import { ReactComponent as UpperCircle4 } from './prepared/point4/uppercircle.svg';
import { ReactComponent as P4_1 } from './prepared/point4/p4-1.svg';
import { ReactComponent as P4_2 } from './prepared/point4/p4-2.svg';
import { ReactComponent as P4_3 } from './prepared/point4/p4-3.svg';

import { ReactComponent as Circles5 } from './prepared/point5/circles.svg';
import { ReactComponent as Vector5 } from './prepared/point5/Vector.svg';
import { ReactComponent as UpperCircle5 } from './prepared/point5/uppercircle.svg';
import { ReactComponent as P5_1 } from './prepared/point5/p5-1.svg';

import { ReactComponent as Circles6 } from './prepared/point6/circles.svg';
import { ReactComponent as Vector6 } from './prepared/point6/Vector.svg';
import { ReactComponent as Vector62 } from './prepared/point6/Vector2.svg';
import { ReactComponent as UpperCircle6 } from './prepared/point6/uppercircle.svg';
import { ReactComponent as P6_1 } from './prepared/point6/p6-1.svg';
import { ReactComponent as P6_2 } from './prepared/point6/p6-2.svg';
import { ReactComponent as P6_3 } from './prepared/point6/p6-3.svg';
import { ReactComponent as P6_4 } from './prepared/point6/p6-4.svg';
import { ReactComponent as ShowBtn } from './show_btn.svg';
import { ReactComponent as ShowBtnActive } from './show_btn_active.svg';

export default {
  // png
  bespokeConsulting,
  morningstar,
  bank,
  morningstarUser1,
  morningstarUser2,
  morningstarUser3,
  bankUser,
  imageDisplay,
  product1,
  product2,
  product3,
  product4: product1,
  product5: product2,
  product6: product3,
  shots,
  testimonialheadshots,
  
  // svg
  Dot,
  Logo,
  LogoBigger,
  LogoSmaller,
  LeftArrow,
  LinkedIn,
  CloseIcon,
  EditIcon,
  HamburgerIcon,
  CloseMenuIcon,
  LockIcon,
  MarkIcon,
  FolderIcon,
  DownloadIcon,
  GitHub,

  MorningStar,
  Axis,
  Watch,
  
  Circles,
  Vector,
  UpperCircle,
  P1_1,
  P1_2,
  
  Circles2,
  Vector2,
  Vector22,
  UpperCircle2,
  P2_1,
  P2_2,
  P2_3,

  Circles3,
  Vector3,
  UpperCircle3,
  P3_1,
  P3_2,

  Circles4,
  Vector4,
  Vector42,
  UpperCircle4,
  P4_1,
  P4_2,
  P4_3,

  Circles5,
  Vector5,
  UpperCircle5,
  P5_1,

  Circles6,
  Vector6,
  Vector62,
  UpperCircle6,
  P6_1,
  P6_2,
  P6_3,
  P6_4,

  ArrowLeft,
  ArrowRight,
  Comment,
  MorningStart,

  ShowBtn,
  ShowBtnActive,
};
