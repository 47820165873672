import { ContextStateAction } from './Actions';

export enum FieldType {
  Name = 'name',
  Company = 'company',
  Email = 'email',
  Phone = 'phone',
  Topic = 'topic',
  Message = 'message',
}

export type Store = {
  inputs: {
    [FieldType.Name]: string;
    [FieldType.Company]: string;
    [FieldType.Email]: string;
    [FieldType.Phone]: string;
    [FieldType.Topic]: string;
    [FieldType.Message]: string;
  };
  fieldName: FieldType | null;
}

const contextReducer = (state: Store, action: ContextStateAction) => {
  switch (action.type) {
    case 'SET_NAME':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Name]: action.name,
        }
      };
    case 'SET_COMPANY':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Company]: action.company,
        }
      };
    case 'SET_EMAIL':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Email]: action.email,
        }
      };
    case 'SET_PHONE':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Phone]: action.phone,
        }
      };
    case 'SET_TOPIC':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Topic]: action.topic,
        }
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [FieldType.Message]: action.message,
        }
      };
    case 'SET_EDIT_FIELD':
      return {
        ...state,
        fieldName: action.fieldName,
      };

    default:
      throw new Error('contextReducer: unknown action');
  }
};

export default contextReducer;
