import React from 'react';
import ReactDOM from 'react-dom';
// In the future we will use it for connecting store
// import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppSettingsProvider } from 'context/appSettings';

import theme from 'services/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/fonts/Silka-Roman-Webfont/Silka-Roman-Webfont/stylesheet.css';
import './assets/fonts/Basier_Circle_Webfont/stylesheet.css';

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppSettingsProvider>
        <App />
      </AppSettingsProvider>
    </ThemeProvider>
    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
