import { FieldType } from './index';

export type ContextStateAction =
  { type: 'SET_NAME'; name: string }
  | { type: 'SET_COMPANY'; company: string }
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'SET_PHONE'; phone: string }
  | { type: 'SET_TOPIC'; topic: string }
  | { type: 'SET_MESSAGE'; message: string }
  | { type: 'SET_EDIT_FIELD'; fieldName: FieldType | null };

export const setName = (name: string): ContextStateAction => ({
  type: 'SET_NAME',
  name,
});
export const setCompany = (company: string): ContextStateAction => ({
  type: 'SET_COMPANY',
  company,
});
export const setEmail = (email: string): ContextStateAction => ({
  type: 'SET_EMAIL',
  email,
});
export const setPhone = (phone: string): ContextStateAction => ({
  type: 'SET_PHONE',
  phone,
});
export const setTopic = (topic: string): ContextStateAction => ({
  type: 'SET_TOPIC',
  topic,
});
export const setMessage = (message: string): ContextStateAction => ({
  type: 'SET_MESSAGE',
  message,
});
export const setEditField = (fieldName: FieldType | null): ContextStateAction => ({
  type: 'SET_EDIT_FIELD',
  fieldName,
});
