/* eslint-disable no-console */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import marked from 'marked';
import Images from 'assets/img';
import PageLayout from 'components/general/PageLayout/PageLayout';
import articles from "../../articles";

const useStyles = makeStyles((theme) => ({
  block: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  article: {
    marginTop: 45,
    textAlign: 'left',
    position: 'relative',
    fontFamily: 'Silka',
    '& p, & li': {
      fontFamily: 'Silka',
      fontSize: 18,
      lineHeight: '26px',
    },
    '& img': {
      maxWidth: '100%'
    }, 
    '& img[title="logo"]': {
      width: 71,
      height: 28,
    },
    '& .tag': {
      alignItems: 'center',
      padding: '2px 4px',
      background: '#1A194F',
      border: '1px solid #1A194F',
      borderRadius: 5,
      color: 'white',
      display: 'inline',
      position: 'absolute',
      top: -25,
    },
    '& h1': {
      fontFamily: 'Silka',
      fontWeight: 'bold',
      fontSize: 48,
      lineHeight: '58px',
      letterSpacing: '-0.01em',
      marginTop: 10,
      color: '#1A194F',
    },
    '& h3': {
      fontFamily: 'Silka',
      fontWeight: 'bold',
      fontSize: 30,
      lineHeight: '36px',
      letterSpacing: '-0.01em',
      color: '#1A194F',
    },
    '& .sub-title': {
      fontFamily: 'Silka',
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: '-0.02em',
    },
  },
  actionButton: {
    textTransform: 'lowercase',
    border: 'none',
    fontWeight: 700,
    marginLeft: -8,

    '&:hover': {
      border: 'none',
      textDecoration: 'underline',
    },

    '&.top': {
      '& svg': {
        transform: 'rotate(90deg)',
      }
    },
    '&.next': {
      '& svg': {
        transform: 'rotate(180deg)',
      }
    },
  },
  actionSection: {
    marginTop: 120,
    display: 'flex',
    justifyContent: 'space-between',
  }
}), {
  name: 'ArticlePage',
});

const ArticlePage = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const history = useHistory();

  const [activeArticle, setActiveArticle] = useState<any>(undefined);

  useEffect(() => {
    if (!activeArticle) return;
    fetch(activeArticle.default)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setText(marked(text));
      })
  }, [activeArticle]);

  useEffect(() => {
    const article = history.location.hash.replace('#', '');
    setActiveArticle(articles[article]);
  }, []);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const onActionClick = useCallback((number) => () => {
    const article = Object.keys(articles)[
      Math.abs((Object.keys(articles).indexOf(
        history.location.hash.replace('#', '')) + number
      )) % Object.keys(articles).length
    ];
    if (article) {
      history.push(`/article#${article}`);
      setActiveArticle(articles[article]);
      scrollTop();
    }
  }, [history]);

  return (
    <PageLayout displayGoBackButton>
      {
        !activeArticle && (
          <Box marginBottom={10}>
            <Typography variant='h2'>No such article</Typography>
          </Box>
        )
      }
      {
        !!activeArticle && (
          <Box className={clsx(classes.block, 'md-text-block')}>
            <section className={classes.article}>
              {/* eslint-disable-next-line react/no-danger */}
              <article dangerouslySetInnerHTML={{__html: text}} />
            </section>
            <Box className={classes.actionSection}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onActionClick(-1)}
                className={clsx(classes.actionButton)}
                startIcon={<Images.LeftArrow />}
              >
                previous
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={scrollTop}
                className={clsx(classes.actionButton, 'top')}
                startIcon={<Images.LeftArrow />}
              >
                back to top
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={onActionClick(1)}
                className={clsx(classes.actionButton, 'next')}
                startIcon={<Images.LeftArrow />}
              >
                next
              </Button>
            </Box>
          </Box>
        )
      }
    </PageLayout>
  );
};

export default ArticlePage;
