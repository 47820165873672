export type setIsLoggedAction = "SET_IS_LOGGED";
export type setCurrentSectionAction = "SET_CURRENT_SECTION";
export type setLasrProductSectionAction = "SET_LAST_PRODUCT_SECTION";
export type addFileInQueueAction = "ADD_FILE_IN_DOWNLOAD_QUEUE";
export type deleteFileFromQueueAction = "DELETE_FILE_IN_DOWNLOAD_QUEUE";

const setIsLoggedValue = "SET_IS_LOGGED";
const setCurrentSectionValue = "SET_CURRENT_SECTION";
const setLasrProductSectionValue = "SET_LAST_PRODUCT_SECTION";
const addFileInQueueValue = "ADD_FILE_IN_DOWNLOAD_QUEUE";
const deleteFileFromQueueValue = "DELETE_FILE_IN_DOWNLOAD_QUEUE";

export {
  setIsLoggedValue,
  setCurrentSectionValue,
  setLasrProductSectionValue,
  addFileInQueueValue,
  deleteFileFromQueueValue
};
