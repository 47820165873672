import React, { useCallback } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tags: {

    },
    title: {
      marginTop: 8,
      '& p': {
        fontFamily: 'Silka',
        fontWeight: 'bold',
        fontSize: 26,
        lineHeight: '31px',
        letterSpacing: '-0.01em',
        color: '#1A194F',
        textTransform: 'capitalize',
      }
    },
    line: {
      border: '1px solid #1A194F',
      width: 151,
      marginTop: 16,
      marginBottom: 16,
    },
    description: {
      '& p': {
        fontFamily: 'Silka',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '22px',
        letterSpacing: '-0.01em',
        color: '#1A194F',
      }
    },
    textButton: {
      textAlign: 'left',
      marginLeft: -10,
    },
    labels: {
      '& img': {
        marginRight: 8,
      },
    },
    actions: {

    },
    top: {

    },
    bottom: {

    },
    tag: {
      background: '#1A194F',
      color: 'white',
      border: '1px solid #1A194F',
      borderRadius: 5,
      padding: '2px 4px',
      fontSize: 16,
      lineHeight: '19px',
      textAlign: 'center',
      letterSpacing: '-0.02em',
      width: 'max-content',
      fontFamily: 'Silka',
    }
  }),
  { name: 'Card' },
);

const Tag = ({ children }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.tag}>
      {children}
    </div>
  )
}

const Card = ({
  tag,
  title,
  description,
  images,
  link,
  ref,
}: {
  tag: string;
  title: string;
  description: string;
  images: string[];
  link: string;
  ref?: any;
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  return (
    <div className={classes.root}>
      <Box className={classes.top}>
        <Box className={classes.tags}>
          <Tag>{tag}</Tag>
        </Box>
        <Box className={classes.title}>
          <Button variant="text" className={classes.textButton} onClick={handleClick}>
            <Typography>
              {title}
            </Typography>
          </Button>
        </Box>
        <div className={classes.line} />
        <Box className={classes.description}>
          <Typography>
            {description}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.bottom}>
        <Box className={classes.labels}>
          {
            images.map((el) => (
              <img src={el} key={`img-${el.replace(' ', '_')}`} alt="tag_image" height={28} />
            ))
          }
        </Box>
      </Box>
    </div>
  )
}

export default Card;
