import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Modal,
  Typography,
  Box
} from '@material-ui/core';
import { 
  Alert
} from '@material-ui/lab';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Images from 'assets/img';
import ROUTES from 'constants/routes';
import { setCurrentSection, setIsLogged, useAppSettingsState } from 'context/appSettings';
import { useHistory, useLocation, Link } from 'react-router-dom';
import * as yup from 'yup';
import auth from 'services/api/AuthApi';
import Button from '../Button/Button';
import HashMenuLink from '../HashMenuLink/HashMenuLink';
import VideoBlock from './VideoBlock';
import MobileMenu from './MobileMenu';

import LoginModal from '../LoginModal';

type HeaderBackground = {
  headerBackground: string;
};

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const schema = yup.object().shape({
  password: yup.string().label('Password').min(3).required().trim(),
  email: yup.string().label('Email').matches(emailRegExp, 'Email is not valid').required().trim(),
});

const useStyles = makeStyles<Theme, HeaderBackground>(
  (theme: Theme) => ({
    appBar: {
      transition: 'height 0.5s ease-out',
      height: (data: HeaderBackground) => data.headerBackground === 'transparent' ? 150 : 84,
      boxShadow: 'none',
      backgroundColor: (data: HeaderBackground) => data.headerBackground,
    },
    sectionDesktop: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
      alignItems: 'center',
      paddingRight: theme.spacing(15),
      paddingLeft: theme.spacing(13),
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(6),
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
      }
    },
    buttonGroup: {
      width: '55%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    hamburger: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      }
    },
    item: {
      fontSize: (data: HeaderBackground) => data.headerBackground === 'transparent' ? 16 : 14, 
      marginRight: theme.spacing(7),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(5),
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(2),
      },
    },
    button: {
      height: (data: HeaderBackground) => data.headerBackground === 'transparent' ? 56 : 44, 
      padding: (data: HeaderBackground) => data.headerBackground === 'transparent' ? '14px 28px' : '8px 28px', 
      fontSize: (data: HeaderBackground) => data.headerBackground === 'transparent' ? 16 : 14, 
    },
  }),
  { name: 'Header' },
);

function Header() {
  const [headerBackground, setHeaderBackground] = useState<string>('transparent');
  const [logoType, setLogoType] = useState('small');
  const [open, setOpen] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const { appContext, appContextDispatch } = useAppSettingsState();

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 0) {
      if (headerBackground !== '#1A194F') {
        setHeaderBackground('#1A194F');
        setLogoType('bigger');
      }
    } else {
      if (headerBackground !== 'transparent') {
        setHeaderBackground('transparent');
        setLogoType('small');
      }
    }
  }, [headerBackground, location]);
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
  }, [headerBackground, location]);

  const classes = useStyles({ headerBackground });

  const goToPage = useCallback((page) => history.push(page), []);

  const handleClick = useCallback((route: string) => () => {
    appContextDispatch(setCurrentSection(route));
  }, []);

  const logo = useMemo(() => logoType !== 'small' ? <Images.LogoSmaller /> : <Images.LogoBigger />, [logoType]);

  const handleLoginOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleLoginClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await auth.logout();
      appContextDispatch(setIsLogged(false));
      localStorage.removeItem('token');
    // eslint-disable-next-line no-empty
    } catch(e) {}
    history.push('/');
  }, []);

  return (
    <>
      <AppBar className={classes.appBar} position="fixed">
        <div className={classes.sectionDesktop}>
          <Link to="/">{logo}</Link>
          <MobileMenu />
          <div className={classes.buttonGroup}>
            <HashMenuLink
              className={classes.item}
              to={`/#${ROUTES.HOME_HASH}`}
              onClick={handleClick(ROUTES.HOME_HASH)}
              active={appContext.currentSection === ROUTES.HOME_HASH}
            >
              Home
            </HashMenuLink>
            <HashMenuLink
              className={classes.item}
              to={`/#${ROUTES.OUR_SERVICE}`}
              onClick={handleClick(ROUTES.OUR_SERVICE)}
              active={appContext.currentSection === ROUTES.OUR_SERVICE}
            >
              Our Services
            </HashMenuLink>
            <HashMenuLink
              className={classes.item}
              to={`/#${ROUTES.OUR_WORK}`}
              onClick={handleClick(ROUTES.OUR_WORK)}
              active={appContext.currentSection === ROUTES.OUR_WORK}
            >
              Our Work
            </HashMenuLink>
            <HashMenuLink
              className={classes.item}
              to={`/#${ROUTES.CLIENT_REVIEW}`}
              onClick={handleClick(ROUTES.CLIENT_REVIEW)}
              active={appContext.currentSection === ROUTES.CLIENT_REVIEW}
            >
              Client Reviews
            </HashMenuLink>
            <Button
              className={classes.item}
              buttonType="secondaryBtn"
              onClick={() => goToPage(ROUTES.CONTACT)}
            >
              Contact
            </Button>
            {false && !appContext.isLogged && (
              <Button className={classes.button} buttonType="primaryBtn" onClick={handleLoginOpen}>Login</Button>
            )}
            {false && appContext.isLogged && (
              <Button className={classes.button} buttonType="primaryBtn" onClick={handleLogout}>Logout</Button>
            )}
          </div>
        </div>
      </AppBar>
      <VideoBlock />
      <LoginModal open={open} handleLoginClose={handleLoginClose}  />
    </>
  );
}

export default Header;
