/* eslint-disable import/prefer-default-export */
import { FieldInputProps, FormikProps, getIn } from 'formik';

export const getFieldErrorMeta = (form: FormikProps<any>, field: FieldInputProps<any>) => {
  const { errors, touched } = form;
  const { name } = field;

  const fieldError = getIn(errors, name);
  const isFieldTouched = getIn(touched, name);
  const showError = !!fieldError && !!isFieldTouched;
  return {
    fieldError,
    showError,
  };
};
