/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import Images from 'assets/img';
import clsx from 'clsx';
import { useStyles } from '../styles';

const POINT = 4;
const Point4 = ({ state, onPointEnter }: {
  state: number;
  onPointEnter: (number: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const classes = useStyles();

  const isHidden = useMemo(() => state !== POINT, [state]);
  const getIconBtn = useMemo(() => {
    if (isHidden) {
      if (state !== -1) {
        return <Images.ShowBtn />
      }
      return <Images.ShowBtnActive />;
    }
    return <Images.ShowBtnActive />;
  }, [state]);

  return (
    <div className={clsx(classes.block, 'p4')}>
      <div className={clsx(classes.bubble, 'p4', {
        'hidden': isHidden,
      })}
      >
        <div className="header">
          <div className="title">
            <Typography>Model Development</Typography>
          </div>
          <div onClick={onPointEnter(-1)}>
            <Images.CloseIcon />
          </div>
        </div>
        <div className="content">
          <Typography>
            We develop robust predictive, classification, time series forecasting and clustering models for any industries. Also, leveraging our quantitative finance background, we develop pricing, valuation and other cash flow models for complex financial products.
          </Typography>
        </div>
      </div>
      <div
        className={clsx(classes.item, classes.point4, {
            [classes.point]: state === -1,
            [classes.active]: !isHidden,
        })}
        onClick={onPointEnter(POINT)}
        // onMouseLeave={onPointEnter(-1)}
      >
        <div className={clsx(classes.circleBlock, 'p4')}>
          <Images.UpperCircle4 className='upperCircle' />
          <Images.Vector4 className='vector' height={41} />
          <Images.Vector42 className='vector2' height={21} />
          <Images.Circles4 className='circles' />
        </div>
        <div className={clsx(classes.pointTitle, {
            'active': !isHidden,
            'not-active': isHidden && state !== -1,
          })}
        >
          <Typography className="labelWithIcon">Model Development {getIconBtn}</Typography>
        </div>
      </div>
      <Images.P4_1 className={clsx(classes.label, 'p4-1', {
        [classes.hidden]: false,
      })}
      />
      <div className={clsx(classes.connector, 'p4-1', {
        [classes.hidden]: false,
      })}
      />
      <Images.P4_2 className={clsx(classes.label, 'p4-2', {
        [classes.hidden]: false,
      })}
      />
      <div className={clsx(classes.connector, 'p4-2', {
        [classes.hidden]: false,
      })}
      />
      <Images.P4_3 className={clsx(classes.label, 'p4-3', {
        [classes.hidden]: false,
      })}
      />
      <div className={clsx(classes.connector, 'p4-3', {
        [classes.hidden]: false,
      })}
      />
    </div>
  );
}

export default Point4;
