import React, { useCallback, useEffect, useRef } from 'react';

import { Box, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';

import PageLayout from 'components/general/PageLayout/PageLayout';
import IntroBlock from 'components/Intro/IntroBlock/IntroBlock';
import PartnersLane from 'components/Intro/Partners/PartnersLine';
import Points from 'components/Intro/Points/Points';
import MobilePoints from 'components/Intro/MobilePoints/MobilePoints';
import OurWork from 'components/Intro/OurWork/OurWork';
import ImageCarousel from 'components/Intro/Carousel/Carousel';
import ROUTES from 'constants/routes';
import { setCurrentSection, useAppSettingsState } from 'context/appSettings';

const useStyles = makeStyles(() => ({}), {
  name: 'MainPage',
});

const MainPage = () => {
  const block1 = useRef<any>(null);
  const block2 = useRef<any>(null);
  const block3 = useRef<any>(null);

  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { appContext, appContextDispatch } = useAppSettingsState();


  useEffect(() => {
    document.title = "Pylink Ltd. - Home";
  }, []);

  const scrollListener = useCallback(() => {

    if ( block1.current === null || block2.current === null || block3.current === null) return;

    const sectionsRange = {
      block1: {
        sectionStart: block1.current.offsetTop,
        sectionEnd:  block1.current.offsetTop + block1.current.offsetHeight,
      },
      block2: {
        sectionStart: block2.current.offsetTop,
        sectionEnd:  block2.current.offsetTop + block2.current.offsetHeight,
      },
      block3: {
        sectionStart: block3.current.offsetTop,
        sectionEnd:  block3.current.offsetTop + block3.current.offsetHeight,
      },
    };

    const node =  block2.current.childNodes[0]
    const nodeStyle = window.getComputedStyle(node)
    const marginTopBlock2 = nodeStyle.getPropertyValue('margin-top').replace('px', '')

    if (sectionsRange.block1.sectionStart > window.scrollY) {
      // if(window.innerWidth > 960) {
      //   window.history.pushState("", "Intro", `/${ROUTES.OUR_SERVICE}`);
      // }
      appContextDispatch(setCurrentSection(ROUTES.HOME_HASH));
    }

    if (sectionsRange.block1.sectionStart - 150 < window.scrollY && sectionsRange.block1.sectionEnd > window.scrollY + 200) {
      if(window.innerWidth > 960) {
        window.history.pushState("", "Intro", `/${ROUTES.OUR_SERVICE}`);
      }
      appContextDispatch(setCurrentSection(ROUTES.OUR_SERVICE));
    }

    if (sectionsRange.block2.sectionStart - 200 < window.scrollY + +marginTopBlock2
      && sectionsRange.block2.sectionEnd > window.scrollY + 200
    ) {
      if(window.innerWidth > 960) {
        window.history.pushState("", "Our Work", `/${appContext.lastProductSection}`);
      }
      appContextDispatch(setCurrentSection(ROUTES.OUR_WORK));
    }
    if (sectionsRange.block3.sectionStart - 200 < window.scrollY && sectionsRange.block3.sectionEnd > window.scrollY + 200) {
      if(window.innerWidth > 960) {
        window.history.pushState("", "Client Reviews", `/${ROUTES.CLIENT_REVIEW}`);
      }
      appContextDispatch(setCurrentSection(ROUTES.CLIENT_REVIEW));
    }
  }, [ appContext ]);

  useEffect(() => {
    document.removeEventListener('scroll', scrollListener);
    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, [appContext]);

  return (
    <PageLayout>
      <div>
        <Box mx={{ xs: 2.5, md: 0 }}>
          <IntroBlock />
        </Box>
      </div>
      <div>
        <Box my={9}>
          <PartnersLane />
        </Box>
      </div>
      <div id={ROUTES.OUR_SERVICE} ref={block1}>
        <Box paddingTop={4.5}>
          {isDownSm ? <MobilePoints /> : <Points />}          
        </Box>
      </div>
      <div style={{ marginBottom: 96 }} id={ROUTES.OUR_WORK} ref={block2}>
        <OurWork />
      </div>
      <div style={{ marginBottom: 120 }} id={ROUTES.CLIENT_REVIEW} ref={block3}>
        <ImageCarousel />
      </div>
    </PageLayout>
  );
};

export default MainPage;
