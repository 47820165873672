import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PageLayout from 'components/general/PageLayout/PageLayout';
import Stories from 'components/ContactUsPage/Stories/Stories';

import { ContextProvider } from 'components/ContactUsPage/context';

import Images from 'assets/img';
import FormData from '../../components/ContactUsPage/Stepper/FormData';

import Content from './Content';
import Footer from './Footer';

const useStyles = makeStyles(
  (theme) => ({
    logo: {
      position: 'fixed',
      margin: theme.spacing(5),
      top: 0,
      left: 0,
      zIndex: 9,
    },
    pageTitle: {
      fontSize: 48,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: 32,
      }
    },
    storyBox: {
      width: '25%',
      minWidth: 400,
      [theme.breakpoints.down('md')]: {
        width: '15%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    contentBox: {
      width: '75%',
      height: 'auto',
      backgroundColor: '#f8f8f8',
      paddingLeft: 80,
      paddingTop: 50,
      maxHeight: '100vh',
      overflow: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '85%',
        paddingLeft: 60,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 80,
        paddingTop: 70,
      }
    },
    closeIcon: {
      position: 'fixed',
      right: 40,
      top: 28,
    },
    scrollable: {
      overflowY: 'auto',
      maxHeight: '100%',
    },
  }),
  {
    name: 'ContactUs',
  },
);

const ContactUsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    document.title = 'Pylink Ltd. - Contact us';
  }, []);

  return (
    <Box display="flex" flexDirection="row">
      <Link to="/">
        <Images.Logo className={classes.logo} />
      </Link>
      <IconButton
        aria-label="delete"
        className={classes.closeIcon}
        onClick={() => history.goBack()}
      >
        <Images.CloseIcon />
      </IconButton>
      <Box className={classes.storyBox}>
        <Stories />
      </Box>
      <Box className={classes.contentBox}>
        <PageLayout displayGoBackButton hideTopBlock noPaddings hideFooter noOverflow>
          <ContextProvider>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={5}>
                <Typography className={classes.pageTitle}>
                  Contact us
                </Typography>
                <Content />
              </Grid>
              <Grid item xs={7} className={classes.scrollable}>
                <FormData />
              </Grid>
            </Grid>
          </ContextProvider>
          <Footer />
        </PageLayout>
      </Box>
    </Box>
  );
};

export default ContactUsPage;
