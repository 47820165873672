import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Images from 'assets/img';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    left: {
      height: 1,
      background: '#999',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      width: 'min-content',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        width: '100%',
        '& svg': {
          width: 'auto',
          height: 20,
        }
      }
    },
    right: {
      height: 1,
      background: '#999',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    }
  }),
  { name: 'PartnersLane' },
);

const PartnersLane = () => {
  const classes = useStyles();
  const root = useRef<HTMLDivElement>(null);
  const center = useRef<HTMLDivElement>(null);
  const [centerWidth, setCenterWidth] = useState(0);

  useEffect(() => {
    const resize = () => {
      setCenterWidth((root.current?.clientWidth || 0) - (center.current?.clientWidth || 0));
    };
    resize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div className={classes.root} ref={root}>
      <div
        className={classes.left}
        style={{
          width: (centerWidth / 2)
        }}
      />
      <div className={classes.center} ref={center}>
        <Box ml={{ xs: 2.5, md: 5 }}><Images.MorningStar /></Box>
        <Box mx={{ xs: 3, sm: 7 }}><Images.Watch /></Box>
        <Box mr={{ xs: 2.5, md: 5}}><Images.Axis /></Box>
      </div>
      <div
        className={classes.right}
        style={{
          width: (centerWidth / 2)
        }}
      />
    </div>
  );
};

export default PartnersLane;
