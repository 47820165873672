import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import ArticlePage from 'pages/ArticlePage/ArticlePage';
import IntroPage from 'pages/Intro/IntroPage';
import ContactUsPage from 'pages/ContactUs/ContactUs';
import PrivacyTerms from 'pages/Privacy&Terms/Privacy&Terms';
import TransactionList from 'pages/TransactionList/TransactionList';
import ROUTES,
  {
    HOME_LINKS,
    USER_TRANSACRION_LIST
} from 'constants/routes';
import { setIsLogged, useAppSettingsState } from 'context/appSettings';
import CookiesNotification from './services/cookies';

const SecuredRoute = (props: RouteProps) => {
  if (localStorage.getItem('token')) {
    return <Route {...props} />
  }

  return <Redirect from="*" to={ROUTES.HOME} />;
}

const App = () => {
  const { appContextDispatch } = useAppSettingsState();

  useEffect(() => {
    if(localStorage.getItem('token')) {
      appContextDispatch(setIsLogged(true));
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={ROUTES.LOGIN} component={() => null} />
        <Route exact path={ROUTES.PRIVACY} component={PrivacyTerms} />
        <Route exact path={ROUTES.TERMS} component={PrivacyTerms} />
        <Route exact path={ROUTES.CONTACT} component={ContactUsPage} />
        <Route exact path={HOME_LINKS} component={IntroPage} />
        <Route exact path="/article" component={ArticlePage} />
        <SecuredRoute exact path={USER_TRANSACRION_LIST} component={TransactionList} />
        <Redirect from="*" to={ROUTES.HOME} />
      </Switch>
      <CookiesNotification />
    </BrowserRouter>
  );
};

export default App;
