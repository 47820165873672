import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Header from 'components/general/Header/Header';
import colors from 'constants/colors';
import { PADDING_LAYOUT_SPACING } from 'constants/styling';
import Images from 'assets/img';
import Footer from '../Footer/Footer';
import ROUTES from '../../../constants/routes';

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      backgroundColor: colors.backgroundGrey,
      overflowX: 'hidden',
    },
    backButton: {
      textTransform: 'lowercase',
      border: 'none',
      fontWeight: 700,
      marginLeft: -8,

      '&:hover': {
        border: 'none',
        textDecoration: 'underline',
      },
    },
    fullHeight: {
      height: '100%',
    },
    noOverflow: {
      overflow: 'hidden',
    },
    pagePaddings: {
      margin: theme.spacing(0, PADDING_LAYOUT_SPACING),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(6),
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(2.5),
        marginLeft: theme.spacing(2.5),
      }
    },
  }),
  { name: 'PageLayout' },
);

interface PageLayoutProps {
  noPaddings?: boolean;
  hideTopBlock?: boolean;
  fullHeight?: boolean;
  hideFooter?: boolean;
  noOverflow?: boolean;
  displayGoBackButton?: boolean;
  children: React.ReactNode;
}

const PageLayout = ({ noOverflow, displayGoBackButton, noPaddings, children, hideTopBlock, hideFooter, fullHeight }: PageLayoutProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.hash && location.hash !== ROUTES.HOME_HASH) {
      const element = document.querySelector(location.hash);
      if (element) {
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();
        const offset   = elemRect.top - bodyRect.top;

        window.scroll({
          top: offset - 100,
          left: 0,
          behavior: 'smooth',
        });
      }
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <div className={clsx(classes.main, {
      [classes.fullHeight]: fullHeight,
      [classes.noOverflow]: noOverflow
    })}
    >
      {!hideTopBlock && <Header />}
      <main className={clsx({
        [classes.fullHeight]: fullHeight
      })}
      >
        <Box className={clsx({
            [classes.pagePaddings]: !noPaddings,
            [classes.fullHeight]: fullHeight,
          })}
        >
          {displayGoBackButton && (
            <Box mb={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => history.push('/')}
                className={classes.backButton}
                startIcon={<Images.LeftArrow />}
              >
                take me back
              </Button>
            </Box>
          )}
          <>{children}</>
        </Box>
      </main>

      {!hideFooter && <Footer />}
    </div>
  );
};

export default PageLayout;
