import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Button as MaterialButton } from '@material-ui/core';
import { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

interface ButtonProps extends MaterialButtonProps {
  buttonType: 'secondaryBtn' | 'primaryBtn' | 'blueBtn' | 'mainBtn';
  isLoading?: boolean;
  className?: string;
}

const useStyles = makeStyles<Theme, ButtonProps>({
  button: {
    padding: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'secondaryBtn': 
          return '0px 0px';
        default:
          return '14px 28px';
      }
    },
    color: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'blueBtn': 
          return '#1A194F';
        default:
          return 'white';
      }
    },
    boxSizing: 'border-box',
    textTransform: 'none',
    fontWeight: 700,
    fontFamily: "'Silka', sans-serif",
    height: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'secondaryBtn': 
          return 'auto';
        default:
          return 56;
      }
    },
    fontSize: 16,

    /* Set up the hover */
    /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
    backgroundImage: 'linear-gradient(white, white)',
    backgroundSize: '0 2px, auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    transition: 'all .2s ease-out',
    border: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'secondaryBtn': 
          return 'none';
        default:
          return `1px solid ${buttonType === 'primaryBtn' ? 'white' : '#1A194F'}`;
      }
    },
    borderRadius: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'secondaryBtn': 
          return '0px';
        default:
          return `3px`;
      }
    },
    backgroundColor: ({ buttonType }: ButtonProps) => {
      switch (buttonType) {
        case 'mainBtn': 
          return '#1A194F';
        default:
          return 'transparent';
      }
    },
    '&:hover': {
      backgroundSize: ({ buttonType }: ButtonProps) => {
        switch (buttonType) {
          case 'secondaryBtn': 
            return '100% 2px, auto';
          default:
            return 0;
        }
      },
      color: 'white',
      backgroundColor: ({ buttonType }: ButtonProps) => {
        switch (buttonType) {
          case 'secondaryBtn': 
            return 'transparent';
          default:
            return '#1863F4';
        }
      },
      border: ({ buttonType }: ButtonProps) => {
        switch (buttonType) {
          case 'secondaryBtn': 
            return 'none';
          default:
            return `1px solid #1863F4`;
        }
      },
    },
  },
});

function Button(props: ButtonProps) {
  const { isLoading = false, disabled, children, className, buttonType, ...other } = props;
  const classes = useStyles({...props} as ButtonProps);

  return (
    <MaterialButton {...other} className={clsx(classes.button, className)} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress size={20} /> : children}
    </MaterialButton>
  );
}

export default Button;
