import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core/styles';

import Images from 'assets/img';

import Video from 'assets/pylink head video.mp4';

const ANIMATION_DURATION = 15000;

const useStyles = (parentProps: any) =>  makeStyles<Theme>((theme: Theme) => {
  return ({
  videoBlock: {
    marginBottom: theme.spacing(5),
    position: 'relative',
    height: '350px',
    overflow: 'hidden',
    animation: `$clipAnimation ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: Math.abs(parentProps.height - 450),
      minHeight: 420,
      animation: `$clipAnimationMobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    }
  },
  video: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    minWidth: '100vw',
  },
  mainBackground: {
    position: 'absolute',
    opacity: 0.3,
    zIndex: 2,
    width: '100%',
    height: '350px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    background:
      `radial-gradient(44.67% 477.25% at 100% 0%, rgba(123, 97, 255, 0.3) 0%, rgba(123, 97, 255, 0) 100%), radial-gradient(97.36% 383.06% at 0% 0%, rgba(34, 71, 203, 0.3) 0%, rgba(21, 101, 255, 0) 100%), linear-gradient(0deg, #AEAEAE, #AEAEAE)`,
    backgroundBlendMode: 'normal, normal, multiply, normal',
    mixBlendMode: 'normal',
    [theme.breakpoints.down('sm')]: {
      height: Math.abs(parentProps.height - 450),
      minHeight: 420,
    }
  },
  kor: {
    position: 'absolute',
    zIndex: 99,
    marginLeft: -7,
    marginTop: 2,
  },
  dot1: {
    animation: `$dot1 ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.down('sm')]: {
      animation: `$dot1Mobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    },
    [theme.breakpoints.down('xs')]: {
      animation: `$dot1Mobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    }
  },
  dot2: {
    animation: `$dot2 ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.down('sm')]: {
      animation: `$dot2Mobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    }
  },
  dot3: {
    animation: `$dot3 ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.down('sm')]: {
      animation: `$dot3Mobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    }
  },
  dot4: {
    animation: `$dot4 ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    [theme.breakpoints.down('sm')]: {
      animation: `$dot4Mobile ${ANIMATION_DURATION}ms ${theme.transitions.easing.easeInOut} infinite`,
    }
  },
  "@keyframes clipAnimation": {
    "0%": {
      clipPath: 'polygon(100% 0, 100% 95%, 71% 100%, 51% 55%, 32% 86%, 14% 74%, 0 100%, 0 0)',
    },
    "50%": {
      clipPath: 'polygon(100% 0, 100% 100%, 71% 83%, 51% 92%, 32% 66%, 14% 100%, 0 80%, 0 0)',
    },
    "100%": {
      clipPath: 'polygon(100% 0, 100% 95%, 71% 100%, 51% 55%, 32% 86%, 14% 74%, 0 100%, 0 0)',
    },
  },
  "@keyframes clipAnimationMobile": {
    "0%": {
      clipPath: `polygon(100% 0, 100% 95%, 100% ${Math.abs(parentProps.height - 390)}px, 80% ${Math.abs(parentProps.height - 520)}px, 30% ${Math.abs(parentProps.height - 400)}px, 0 ${Math.abs(parentProps.height - 500)}px, 0 0)`,
    },
    "50%": {
      clipPath: `polygon(100% 0, 100% 100%, 100% ${Math.abs(parentProps.height - 530)}px, 80% ${Math.abs(parentProps.height - 390)}px, 30% ${Math.abs(parentProps.height - 500)}px, 0 ${Math.abs(parentProps.height - 400)}px, 0 0)`,
    },
    "100%": {
      clipPath: `polygon(100% 0, 100% 95%, 100% ${Math.abs(parentProps.height - 390)}px, 80% ${Math.abs(parentProps.height - 520)}px, 30% ${Math.abs(parentProps.height - 400)}px, 0 ${Math.abs(parentProps.height - 500)}px, 0 0)`,
    },
  },
  "@keyframes dot1": {
    "0%": {
      top: '56%',
      left: '14%',
    },
    "50%": {
      top: '82%',
      left: '14%',
    },
    "100%": {
      top: '56%',
      left: '14%',
    },
  },
  "@keyframes dot1Mobile": {
    "0%": {
      top: `${Math.abs(parentProps.height - 565)}px`,
      left: 0,
    },
    "50%": {
      top: `${Math.abs(parentProps.height - 465)}px`,
      left: 0,
    },
    "100%": {
      top: `${Math.abs(parentProps.height - 565)}px`,
      left: 0,
    },
  },
  "@keyframes dot2": {
    "0%": {
      top: '68%',
      left: '32%',
    },
    "50%": {
      top: '48%',
      left: '32%',
    },
    "100%": {
      top: '68%',
      left: '32%',
    },
  },
  "@keyframes dot2Mobile": {
    "0%": {
      top: `${Math.abs(parentProps.height - 465)}px`,
      left: '30%',
    },
    "50%": {
      top: `${Math.abs(parentProps.height - 565)}px`,
      left: '30%',
    },
    "100%": {
      top: `${Math.abs(parentProps.height - 465)}px`,
      left: '30%',
    },
  },
  "@keyframes dot3": {
    "0%": {
      top: '37%',
      left: '51%',
    },
    "50%": {
      top: '74%',
      left: '51%',
    },
    "100%": {
      top: '37%',
      left: '51%',
    },
  },
  "@keyframes dot3Mobile": {
    "0%": {
      top: `${Math.abs(parentProps.height - 582)}px`,
      left: '79.5%',
    },
    "50%": {
      top: `${Math.abs(parentProps.height - 455)}px`,
      left: '79.5%',
    },
    "100%": {
      top: `${Math.abs(parentProps.height - 582)}px`,
      left: '79.5%',
    },
  },
  "@keyframes dot4": {
    "0%": {
      top: '83%',
      left: '71%',
    },
    "50%": {
      top: '65%',
      left: '71%',
    },
    "100%": {
      top: '83%',
      left: '71%',
    },
  },
  "@keyframes dot4Mobile": {
    "0%": {
      top: `${Math.abs(parentProps.height - 457)}px`,
      left: '100%',
    },
    "50%": {
      top: `${Math.abs(parentProps.height - 590)}px`,
      left: '100%',
    },
    "100%": {
      top: `${Math.abs(parentProps.height - 457)}px`,
      left: '100%',
    },
  }
})},
{ name: 'VideoBlock' });

function VideoBlock() {
  const classes = useStyles({ height: window.innerHeight < 720 ? 720 : window.innerHeight })();

  return (
    <div className={classes.videoBlock}>
      <Images.Dot
        className={clsx(classes.dot1, classes.kor)}
      />
      <Images.Dot
        className={clsx(classes.dot2, classes.kor)}
      />
      <Images.Dot
        className={clsx(classes.dot3, classes.kor)}
      />
      <Images.Dot
        className={clsx(classes.dot4, classes.kor)}
      />
      <div className={classes.mainBackground} />
      <video className={classes.video} autoPlay loop muted>
        <source src={Video} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBlock;
