import api from './BaseApiService';

const sendContactUsForm = (data: any) => {
  return api({
    method: 'POST',
    url: '/support/contact-us',
    data
  })
}

const support =  {
  sendContactUsForm,
};

export default support;

