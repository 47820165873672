import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, useTheme, Typography, Collapse, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/general/Button/Button';
import clsx from 'clsx';
import Card from './Card';
import { cards } from './cards';

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: '#1A194F',
      [theme.breakpoints.down('sm')]: {
        fontSize: 32,
        lineHeight: '38px',
        letterSpacing: '-0.02em',
      }
    },
    displayImage: {
      position: 'absolute',
      right: '13%',
      top: '20%',
      width: '32%',
    },
    block: {
      position: 'relative',
      width: '100%',
    },
    mainBlockButton: {
      width: '100%',
    },
    leftButton: {
      marginRight: 32,
    },
    line: {
      height: 2,
      width: 120,
      background: 'linear-gradient(180deg, rgba(234, 234, 234, 0) 0%, #EAEAEA 100%)',
    },
    verticalBlock: {
      fontSize: 8,
      lineHeight: '10px',
      textTransform: 'uppercase',
      letterSpacing: '0.7rem',
      fontWeight: 700,
    },
    topTextBlock: {
      transform: 'rotate(-90deg)',
      position: 'absolute',
      left: '-40%',
      top: '40%',
      display: 'flex',
      width: 300,
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    description: {
      width: '100%',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(9),
    },
    customFontSize: {
      fontSize: 18,
      lineHeight: '28px',
    },
    root: {
      width: '100%',
      transition: 'max-height 2s ease',
    },
    grid: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: 10,
      margin: -10,
    },
    loadMoreSection: {
      width: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'center',
    },
    cardRoot: {
      width: 385,
      minHeight: 380,
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
      background: '#FFFFFF',
      padding: 24,
      
      flex: '0 1 calc(33% - 12px)',
      marginBottom: 24,
      [theme.breakpoints.down('sm')]: {
        flex: '0 1 100%',
      }
    }
  }),
  { name: 'OurWork' },
);

const OurWork = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapsed, setCollapsed] = useState(true);
  const ref = useRef<HTMLDivElement>(null); 
  const [cardHeight, setCardHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      setCardHeight(ref.current?.offsetHeight || 0 + 12);
    });
    setCardHeight(ref.current?.offsetHeight || 0 + 12);
  }, []);

  return (
    <Box className={classes.root} key={`work-card-${cardHeight}`}>
      <div className={classes.block}>
        <Typography variant="h2" className={classes.title}>Our Work</Typography>
      </div>
      <Box className={classes.description}>
        <Typography className={classes.customFontSize}>
          A continuously expanding library of case studies, project works and expert articles showcasing the technologies we use, challenges we came across and the impact our data solutions have on multiple industries.
        </Typography>
      </Box>
      <Collapse in={!collapsed} collapsedHeight={isDownSm ? (3 * cardHeight + 20) : cardHeight}>
        <Box className={clsx(classes.grid)}>
          {
            cards.map((el, index) => (
              <div className={classes.cardRoot} key={`card-${el.id}`} ref={index === 1 ? ref : null}>
                <Card {...el} />
              </div>
            ))
          }
        </Box>
      </Collapse>
      {cards.length > 3 && (
      <Box className={classes.loadMoreSection}>
        <Button
          buttonType="mainBtn"
          onClick={
            () => setCollapsed(state => !state)
          }
        >
          Show {collapsed ? 'more' : 'less'}
        </Button>
      </Box>
      )}
    </Box>
  );
};

export default OurWork;
