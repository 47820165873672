import {
  setIsLoggedAction,
  setCurrentSectionAction,
  setLasrProductSectionAction,
  addFileInQueueAction,
  deleteFileFromQueueAction,

  setIsLoggedValue,
  setCurrentSectionValue,
  setLasrProductSectionValue,
  addFileInQueueValue,
  deleteFileFromQueueValue
} from './constants';

export type ContextStateAction = { type: setIsLoggedAction; state: boolean }
  | { type: setCurrentSectionAction; state: string; }
  | { type: setLasrProductSectionAction; state: string; }
  | { type: addFileInQueueAction; state: string[]; }
  | { type: deleteFileFromQueueAction; state: string[]; };

const setIsLogged = (state: boolean): ContextStateAction => ({
  type: setIsLoggedValue,
  state,
});

const setCurrentSection = (state: string): ContextStateAction => ({
  type: setCurrentSectionValue,
  state,
});

const addFileInQueue = (state: string[]): ContextStateAction => ({
  type: addFileInQueueValue,
  state,
});

const deleteFileFromQueue = (state: string[]): ContextStateAction => ({
  type: deleteFileFromQueueValue,
  state,
});

const setLastProductSection = (state: string): ContextStateAction => ({
  type: setLasrProductSectionValue,
  state,
});

export {
  setIsLogged,
  setCurrentSection,
  setLastProductSection,
  addFileInQueue,
  deleteFileFromQueue
};
