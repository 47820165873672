import { Box, Fade, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import ImageProgress from './Progress';
import StoryText from './StoryText';
import { image } from './img';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      height: '100vh'
    },
    imagesBlock: {
      position: 'relative',
    },
    progressBlock: {
      width: '100%',
      position: 'absolute',
      bottom: 0
    },
    image: {
      position: 'absolute',
      maxHeight: '100vh',
      height: '100vh',
      width: '100%',
      zIndex: 1,
    },
    textBlock: {
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      top: 0,
      padding: theme.spacing(5),
      paddingTop: 0,
      marginTop: '50%',
      maxWidth: 'inherit',
    },
    shadowImage: {
      zIndex: 0,
    },
  }),
  {
    name: 'Stories',
  },
);

const STORY_DURATION = 8000;

const TEXT_TIMEOUT = 1250;
const IMAGE_TIMEOUT = 2500;

const Stories = () => {
  const classes = useStyles();

  const text = useRef<Record<string, string>[]>([
    {
      title: 'Better data understanding',
      description: 'We answer your business challenges by leveraging data solutions',
    },
    {
      title: 'End-to-end custom solutions',
      description: 'We offer end to end solutions from business understanding to software development',
    },
    {
      title: 'Multiple technologies',
      description: 'We work with multiple open-source technologies and choose the one that best fits your needs',
    },
  ]);
  const [activeImage, setActiveImage] = useState<number>(0);

  const handleActiveIndex = useCallback(() => setTimeout(() => {
    setActiveImage(activeImage =>
      activeImage === 2
        ? 0
        : (activeImage + 1)
    );
  }, STORY_DURATION), [setActiveImage]);

  useEffect(() => {
    handleActiveIndex();
  }, [ activeImage ]);

  return (
    <Box className={classes.root}>
      <Box className={classes.imagesBlock}>
        {
          Object.values(image).map((img: string, k: number) => (
            <React.Fragment key={`story-${img}`}>
              <Fade
                timeout={TEXT_TIMEOUT}
                in={k === activeImage}
              >
                <Box className={classes.textBlock}>
                  <StoryText
                    title={text.current[k].title}
                    description={text.current[k].description}
                  />
                </Box>
              </Fade>
              <Fade
                in={k === activeImage}
                timeout={IMAGE_TIMEOUT}
              >
                <img src={img} alt="Story" className={classes.image} />
              </Fade>
            </React.Fragment>
          ))
        }
        <img
          src={Object.values(image)[(activeImage + 1) % 3]} // get next image as background
          alt="background"
          className={clsx(classes.image, classes.shadowImage)}
        />
      </Box>
      <Box p={5} className={classes.progressBlock}>
        <ImageProgress imageNum={activeImage + 1} duration={STORY_DURATION} />
      </Box>
    </Box>
  )
}

export default Stories;
