import {
  setIsLoggedValue,
  setCurrentSectionValue,
  setLasrProductSectionValue,
  addFileInQueueValue,
  deleteFileFromQueueValue
} from './constants';
import { ContextState } from './provider';
import { ContextStateAction } from './actions';

const contextReducer = (state: ContextState, action: ContextStateAction) => {
  switch (action.type) {
    case setIsLoggedValue:
      return {
        ...state,
        isLogged: action.state,
      };
    case setCurrentSectionValue:
      return {
        ...state,
        currentSection: action.state,
      };
    case setLasrProductSectionValue:
      return {
        ...state,
        lastProductSection: action.state,
      };
    case addFileInQueueValue:
      return {
        ...state,
        filesQueue: action.state,
      };
    case deleteFileFromQueueValue:
      return {
        ...state,
        filesQueue: action.state,
      };

    default:
      throw new Error('appSettingsContextReducer: unknown action');
  }
};

// eslint-disable-next-line import/prefer-default-export
export { contextReducer };
