/* eslint-disable no-console */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import clsx from 'clsx';
import { makeStyles, Typography, withWidth, isWidthUp } from '@material-ui/core';

import ROUTES from 'constants/routes';
import colors from 'constants/colors';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(
  (theme) => ({
    message: {
      fontSize: 16,
      color: colors.textDefault,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.02em',
    },
    container: {
      background: `${colors.white} !important`,
      boxShadow: '0px 0px 180px rgba(0, 0, 0, 0.08)',
      borderRadius: 5,
      margin: theme.spacing(5),
      marginTop: 0,
      width: `calc(100% - 80px) !important`,
      zIndex: 9999,
      opacity: 1,
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(2),
      },
    },
    content: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
      flex: '1 0 calc(100% - 80px) !important',
    },
    link: {
      color: colors.textDefault,
      fontWeight: 700,
    },
    buttonWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
    buttonBase: {
      borderRadius: '3px !important',
      fontSize: 16,
      lineHeight: '28px',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      paddingTop: 14,
      paddingBottom: 14,
      width: 'calc(100% - 80px)',
      margin: `${theme.spacing(1, 5)} !important`,
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% - 80px)',
      },
    },
    button: {
      background: `${colors.darkBlue} !important`,
      color: `${colors.white} !important`,
    },
    declineButton: {
      background: `${colors.white} !important`,
      textDecoration: 'underline',
      color: `${colors.textDefault} !important`,
    },
    overlay: {
      background: 'rgba(26, 25, 79, 0.6) !important',
      zIndex: 9999,
    },
  }),
  {
    name: 'Cookies',
  },
);

interface Props {
  width: Breakpoint;
}

const CookiesNotification: FC<Props> = ({ width }) => {
  const classes = useStyles();

  return (
    <CookieConsent
      enableDeclineButton
      overlay={!isWidthUp('md', width)}
      overlayClasses={classes.overlay}
      containerClasses={classes.container}
      contentClasses={classes.content}
      buttonWrapperClasses={classes.buttonWrapper}
      buttonClasses={clsx(classes.buttonBase, classes.button)}
      declineButtonClasses={clsx(classes.buttonBase, classes.declineButton)}
      buttonText="Understand"
      declineButtonText="Decline"
      onAccept={() => {
        console.log('Accept was triggered by clicking the Accept button');
      }}
      onDecline={() => {
        console.log('Cookies declined!');
      }}
    >
      <Typography className={classes.message}>
        We use cookies and related technology to optimize your experience. Some cookies are essential to make our
        website work, while others, like analytics cookies, help us track your interaction and improve your online
        experience. Please see our&nbsp;
        <Link to={ROUTES.PRIVACY} className={classes.link}>
          Cookie Policy
        </Link>{' '}
        for more information.
      </Typography>
    </CookieConsent>
  );
};

export default withWidth()(CookiesNotification);
