import React, { createContext, useContext } from "react";
import { ContextStateAction } from './actions';
import { contextReducer } from './reducer';
import ROUTES from '../../constants/routes';

export type ContextState = {
  isLogged: boolean;
  currentSection: string;
  lastProductSection: string;
  filesQueue: string[];
}

interface ContextProps {
  appContext: ContextState;
  appContextDispatch: React.Dispatch<ContextStateAction>;
}

const createDefaultState = (): ContextState => ({
  isLogged: false,
  currentSection: ROUTES.HOME_HASH,
  lastProductSection: '#pylink-waterfall',
  filesQueue: [],
});

const Context = createContext<ContextProps>({
  appContext: {...createDefaultState()},
  appContextDispatch: (action: ContextStateAction) => {
    throw new Error('Using AppSettings Context without the provider');
  },
});

const AppSettingsProvider: React.FC = ({ children }) => {
  const [appContext, appContextDispatch] = React.useReducer(contextReducer, {...createDefaultState()});

  return <Context.Provider value={{ appContext, appContextDispatch }}>{children}</Context.Provider>;
};

const useAppSettingsState = () => {
  const context = useContext(Context);
  if (context === null) {
    throw new Error("appSettingsState must be used within a AppSettingsProvider");
  }
  return context;
};

export {
  AppSettingsProvider,
  useAppSettingsState,
};