import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import colors from 'constants/colors';
import ROUTES from 'constants/routes';
import Images from 'assets/img';
import PADDING_LAYOUT from 'constants/styling';
import Button from '../Button/Button';

const useStyles = makeStyles((theme) => (
  {
    footer: {
      backgroundColor: colors.darkBlue,
      padding: `50px ${PADDING_LAYOUT}px`,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      }
    },
    logo: {
      marginBottom: 18,
    },
    copy: {
      fontSize: 12,
      display: 'block',
      lineHeight: '16px',
      fontWeight: 600,
      color: colors.textWhite,
    },
    location: {
      fontSize: 12,
      marginTop: 5,
    },
    privacy: {
      fontSize: 14,
      marginTop: 18,
      [theme.breakpoints.down('sm')]: {
        marginRight: 20,
        textDecoration: 'underline'
      }
    },
    terms: {
      fontSize: 14,
      marginTop: 18,
      marginLeft: 48,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        textDecoration: 'underline'
      }
    },
    socialContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      textDecoration: 'none',
      marginTop: 16,
    },
    socialIcon: {
      width: 22,
      height: 22,
      marginRight: 13,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      },
    },
    social: {
      display: 'block',
      lineHeight: '16px',
      fontWeight: 600,
      color: colors.textWhite,
      fontSize: 14,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(5),
      }
    },
  }),
  { name: 'Footer' },
);

const Footer = () => {
  const classes = useStyles();

  const currentYear = new Date().getFullYear();

  return (
    <Grid container className={classes.footer}>
      <Grid item md={8}>
        <Images.LogoSmaller className={classes.logo} />
        <Typography className={classes.copy} variant="caption">
          Copyright &copy; {currentYear} Pylink Ltd. All rights reserved!
        </Typography>
        <Typography className={clsx(classes.copy, classes.location)} variant="caption">
          Pylink Ltd., 7 Bell Yard, London, WC2A 2JR, UK
        </Typography>
        <Button buttonType="secondaryBtn" className={classes.privacy} href={ROUTES.PRIVACY}>
          Privacy Policy
        </Button>
        <Button buttonType="secondaryBtn" className={classes.terms} href={ROUTES.TERMS}>
          Terms & Conditions
        </Button>
      </Grid>
      <Grid item md={4} className={classes.socialContainer}>
        <a href="https://www.linkedin.com/company/18915295" className={classes.link}>
          <Images.LinkedIn className={classes.socialIcon} />
          <Typography className={classes.social} variant="caption">
            Check out our Linkedin
          </Typography>
        </a>
        <a href="https://github.com/Pylink-Waterfall/" className={classes.link}>
          <Images.GitHub className={classes.socialIcon} />
          <Typography className={classes.social} variant="caption">
            Check out our open source projects
          </Typography>
        </a>
      </Grid>
    </Grid>
  );
};

export default Footer;
