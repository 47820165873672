import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';

interface ImageProgressProps {
  imageNum: number;
  duration: number;
}

const defaultProgress: {[key: string]: number} = {
  progress1: 0,
  progress2: 0,
  progress3: 0,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '32%',
      height: 1,
      zIndex: 2,
    },
    mainLineColor: {
      backgroundColor: theme.palette.common.white
    },
    secondaryLineColor: {
      color: theme.palette.common.white,
      opacity: 0.5,
    }
  }),
  {
    name: 'ImageProgress',
  },
);

const MAX_PERCENTS = 100;
const SHIFT_FOR_SMOOTH_CHANGE = 1000 * 0.8; // 1000 milliseconds * 80% -- we need not all 1s, cause some processed are taking some time

const ImageProgress = ({ imageNum, duration }: ImageProgressProps) => {
  const [progress, setProgress] = useState<{[key: string]: number}>(defaultProgress);
  const classes = useStyles();

  const secondsForPercent = useMemo(
    () => Math.round(duration / MAX_PERCENTS - duration / SHIFT_FOR_SMOOTH_CHANGE),
    [ duration ]
  );

  useEffect(() => {
    if(imageNum === 1) {
      setProgress(defaultProgress);
    }
    if(imageNum === 2) {
      setProgress({
        ...defaultProgress,
        progress1: 100,
      });
    }
    if(imageNum === 3) {
      setProgress({
        ...defaultProgress,
        progress1: 100,
        progress2: 100,
      });
    }

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return {
          ...oldProgress,
          [`progress${imageNum}`]: Math.min(oldProgress[`progress${imageNum}`] + 1, 100)
        };
      });
    }, secondsForPercent)

    return () => {
      clearInterval(timer);
    };
  }, [imageNum]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <LinearProgress
        classes={{
        root: classes.root,
        barColorPrimary: classes.mainLineColor,
        barColorSecondary: classes.secondaryLineColor,
      }}
        variant="determinate"
        value={progress.progress1}
      />
      <LinearProgress
        classes={{
        root: classes.root,
        barColorPrimary: classes.mainLineColor,
        barColorSecondary: classes.secondaryLineColor,
      }}
        variant="determinate"
        value={progress.progress2}
      />
      <LinearProgress
        classes={{
        root: classes.root,
        barColorPrimary: classes.mainLineColor,
        barColorSecondary: classes.secondaryLineColor,
      }}
        variant="determinate"
        value={progress.progress3}
      />
    </Box>
  )
}

export default ImageProgress;
